<template>
    <div class="card shadow-none border-none">
        <div
            class="d-flex justify-content-between align-items-center p-3 flex-wrap"
        >
            <div class="custom-label">FILTERS</div>
            <div class="d-flex gap-2">
                <v-btn
                    color="#c8c8c8"
                    size="small"
                    class="text-none"
                    @click="resetFilters"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="indigo-darken-2"
                    class="text-none"
                    @click="applyFilters"
                    size="small"
                >
                    Apply
                </v-btn>
            </div>
        </div>
        <hr class="m-0" />
        <div class="p-3">
            <div class="d-flex gap-2 mb-2 flex-wrap">
                <DateRangeCom
                    class="w-full"
                    v-model.date="dateRange"
                ></DateRangeCom>
                <!-- <div class="flex-grow-1">
                    <div class="custom-label mb-1">Start Date</div>
                    <input
                        type="date"
                        id="startDate"
                        class="form-control"
                        v-model="startDate"
                    />
                </div>
                <div class="flex-grow-1">
                    <div class="custom-label mb-1">End Date</div>
                    <input
                        type="date"
                        id="endDate"
                        class="form-control"
                        v-model="endDate"
                    />
                </div> -->
            </div>
            <div>
                <v-switch
                    v-model="last24Hours"
                    label="Last 24 hours"
                    density="compact"
                    hide-details
                    color="indigo-darken-2"
                    class="custom-switch"
                ></v-switch>
                <v-switch
                    v-model="insideSelectedHourRange"
                    label="Inside selected hour range only"
                    density="compact"
                    hide-details
                    color="indigo-darken-2"
                    class="custom-switch"
                ></v-switch>
                <v-switch
                    v-model="createdInsideDateRange"
                    label="Created inside date range only"
                    density="compact"
                    hide-details
                    color="indigo-darken-2"
                    class="custom-switch"
                ></v-switch>
                <v-switch
                    v-if="!hideFilterOptions.includes('compare_with_past')"
                    v-model="compareWithPast"
                    label="Compare with past"
                    density="compact"
                    hide-details
                    color="indigo-darken-2"
                    class="custom-switch"
                ></v-switch>
            </div>
            
            <div class="mt-3">
                <div class="custom-label mb-2">Publisher</div>
                <v-autocomplete
                    v-model="selectedPublishers"
                    :items="publishers"
                    placeholder="Select publishers"
                    item-title="name"
                    item-value="id"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item, index }">
                        <span>
                            <v-chip
                                v-if="index === 0"
                                small
                                style="margin-left: 0px"
                            >
                                <span>{{ item.title }}</span>
                            </v-chip>
                            <v-chip
                                v-if="index === 1 && selectedPublishers"
                                small
                                class="text-caption"
                                style="font-size: 11px !important"
                            >
                                +{{ selectedPublishers.length - 1 }}
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters"
                        value="affiliate_id"
                        :label="
                            filters.includes('affiliate_id')
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="
                            filters.includes('affiliate_id')
                                ? 'danger'
                                : 'success'
                        "
                        class="custom-switch"
                    ></v-switch>
                </div>
            </div>
            <div class="mt-3">
                <div class="custom-label mb-2">Countries</div>
                <v-autocomplete
                    v-model="selectedCountries"
                    :items="countries"
                    item-title="name"
                    item-value="id"
                    placeholder="Select countries"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item, index }">
                        <span>
                            <v-chip
                                v-if="index === 0"
                                small
                                style="margin-left: 0px"
                            >
                                <span>{{ item.title }}</span>
                            </v-chip>
                            <v-chip
                                v-if="index === 1 && selectedCountries"
                                small
                                class="text-caption"
                                style="font-size: 11px !important"
                            >
                                +{{ selectedCountries.length - 1 }}
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters"
                        value="country_id"
                        :label="
                            filters.includes('country_id')
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="
                            filters.includes('country_id')
                                ? 'danger'
                                : 'success'
                        "
                        class="custom-switch"
                    ></v-switch>
                </div>
            </div>
            <div class="mt-3">
                <div class="custom-label mb-2">Advertisers</div>
                <v-autocomplete
                    v-model="selectedAdvertisers"
                    :items="advertisers"
                    item-title="name"
                    item-value="id"
                    placeholder="Select advertisers"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item, index }">
                        <span>
                            <v-chip
                                v-if="index === 0"
                                small
                                style="margin-left: 0px"
                            >
                                <span>{{ item.title }}</span>
                            </v-chip>
                            <v-chip
                                v-if="index === 1 && selectedAdvertisers"
                                small
                                class="text-caption"
                                style="font-size: 11px !important"
                            >
                                +{{ selectedAdvertisers.length - 1 }}
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters"
                        value="advertiser_id"
                        :label="
                            filters.includes('advertiser_id')
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="
                            filters.includes('advertiser_id')
                                ? 'danger'
                                : 'success'
                        "
                        class="custom-switch"
                    ></v-switch>
                </div>
            </div>
            <div class="mt-3">
                <div class="custom-label mb-2">Statuses</div>
                <v-autocomplete
                    v-model="selectedStatuses"
                    :items="statuses"
                    placeholder="Select statuses"
                    item-title="brand_status"
                    item-value="brand_status"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item, index }">
                        <span>
                            <v-chip
                                v-if="index === 0"
                                density="compact"
                                style="margin-left: 0px"
                            >
                                <span>{{ item.title }}</span>
                            </v-chip>
                            <v-chip
                                v-if="index === 1 && selectedStatuses"
                                density="compact"
                                class="text-caption"
                                style="font-size: 11px !important"
                            >
                                +{{ selectedStatuses.length - 1 }}
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters"
                        value="brand_status"
                        :label="
                            filters.includes('brand_status')
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="
                            filters.includes('brand_status')
                                ? 'danger'
                                : 'success'
                        "
                        class="custom-switch"
                    ></v-switch>
                </div>
            </div>
            <div class="mt-3">
                <div class="custom-label mb-2">Custom Param 1</div>
                <v-autocomplete
                    v-model="selectedCustomParam1"
                    :items="custom_param_1"
                    placeholder="Select custom param 1"
                    item-title="custom_param_1"
                    item-value="custom_param_1"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item }">
                        <span>
                            <v-chip small>
                                <span>{{ item.title }}</span>
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters"
                        value="custom_param_1"
                        :label="
                            filters.includes('custom_param_1')
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="
                            filters.includes('custom_param_1')
                                ? 'danger'
                                : 'success'
                        "
                        class="custom-switch"
                    ></v-switch>
                </div>
            </div>
            <div class="mt-3">
                <div class="custom-label mb-2">Custom Param 2</div>
                <v-autocomplete
                    v-model="selectedCustomParam2"
                    :items="custom_param_2"
                    placeholder="Select custom param 2"
                    item-title="custom_param_2"
                    item-value="custom_param_2"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item }">
                        <span>
                            <v-chip small>
                                <span>{{ item.title }}</span>
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters"
                        value="custom_param_2"
                        :label="
                            filters.includes('custom_param_2')
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="
                            filters.includes('custom_param_2')
                                ? 'danger'
                                : 'success'
                        "
                        class="custom-switch"
                    ></v-switch>
                </div>
            </div>
            <div class="mt-3">
                <div class="custom-label mb-2">Custom Param 3</div>
                <v-autocomplete
                    v-model="selectedCustomParam3"
                    :items="custom_param_3"
                    placeholder="Select custom param 3"
                    item-title="custom_param_3"
                    item-value="custom_param_3"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item }">
                        <span>
                            <v-chip small>
                                <span>{{ item.title }}</span>
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters"
                        value="custom_param_3"
                        :label="
                            filters.includes('custom_param_3')
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="
                            filters.includes('custom_param_3')
                                ? 'danger'
                                : 'success'
                        "
                        class="custom-switch"
                    ></v-switch>
                </div>
            </div>
            <div class="mt-3">
                <div class="custom-label mb-2">Custom Param 4</div>
                <v-autocomplete
                    v-model="selectedCustomParam4"
                    :items="custom_param_4"
                    placeholder="Select custom param 4"
                    item-title="custom_param_4"
                    item-value="custom_param_4"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item }">
                        <span>
                            <v-chip small>
                                <span>{{ item.title }}</span>
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters"
                        value="custom_param_4"
                        :label="
                            filters.includes('custom_param_4')
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="
                            filters.includes('custom_param_4')
                                ? 'danger'
                                : 'success'
                        "
                        class="custom-switch"
                    ></v-switch>
                </div>
            </div>
            <div class="mt-3">
                <div class="custom-label mb-2">Custom Param 5</div>
                <v-autocomplete
                    v-model="selectedCustomParam5"
                    :items="custom_param_5"
                    placeholder="Select custom param 5"
                    item-title="custom_param_5"
                    item-value="custom_param_5"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item }">
                        <span>
                            <v-chip small>
                                <span>{{ item.title }}</span>
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters"
                        value="custom_param_5"
                        :label="
                            filters.includes('custom_param_5')
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="
                            filters.includes('custom_param_5')
                                ? 'danger'
                                : 'success'
                        "
                        class="custom-switch"
                    ></v-switch>
                </div>
            </div>
            <div class="mt-3">
                <div class="custom-label mb-2">Publisher Status</div>
                <v-autocomplete
                    v-model="selectedPublisherStatus"
                    :items="publisher_status"
                    item-title="publisher_status"
                    item-value="publisher_status"
                    placeholder="Select publisher status"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item }">
                        <span>
                            <v-chip small>
                                <span>{{ item.title || null }}</span>
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters"
                        value="affiliate_sale_status"
                        :label="
                            filters.includes('affiliate_sale_status')
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="
                            filters.includes('affiliate_sale_status')
                                ? 'danger'
                                : 'success'
                        "
                        class="custom-switch"
                    ></v-switch>
                </div>
            </div>
            <div class="mt-3">
                <div class="custom-label mb-2">Offer</div>
                <v-autocomplete
                    v-model="selectedOffer"
                    :items="offer"
                    placeholder="Select offer"
                    item-title="offer"
                    item-value="offer"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item }">
                        <span>
                            <v-chip small>
                                <span>{{ item.title }}</span>
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters"
                        value="offer"
                        :label="
                            filters.includes('offer')
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="
                            filters.includes('offer') ? 'danger' : 'success'
                        "
                        class="custom-switch"
                    ></v-switch>
                </div>
            </div>
            <div class="mt-3">
                <div class="custom-label mb-2">Distribution</div>
                <v-autocomplete
                    v-model="selectedDistribution"
                    :items="distribution"
                    placeholder="Select distribution"
                    item-title="name"
                    item-value="id"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item }">
                        <span>
                            <v-chip small>
                                <span>{{ item.title }}</span>
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters"
                        value="dis_id"
                        :label="
                            filters.includes('dis_id')
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="
                            filters.includes('dis_id') ? 'danger' : 'success'
                        "
                        class="custom-switch"
                    ></v-switch>
                </div>
            </div>
            <div class="mt-3">
                <div class="custom-label mb-2">Funnel</div>
                <v-autocomplete
                    v-model="selectedFunnel"
                    :items="funnel"
                    placeholder="Select funnel"
                    item-title="funnel"
                    item-value="funnel"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item }">
                        <span>
                            <v-chip small>
                                <span>{{ item.title }}</span>
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters"
                        value="funnel"
                        :label="
                            filters.includes('funnel')
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="
                            filters.includes('funnel') ? 'danger' : 'success'
                        "
                        class="custom-switch"
                    ></v-switch>
                </div>
            </div>
            <div class="mt-3">
                <div class="d-flex align-center flex-wrap">
                    <div class="custom-label text-black">Is Test</div>
                    <v-radio-group v-model="isTest" inline hide-details>
                        <v-radio label="All" :value="true"></v-radio>
                        <v-radio label="Excluded" :value="false"></v-radio>
                    </v-radio-group>
                </div>
                <!-- <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters"
                        value="is_test"
                        :label="
                            filters.includes('is_test')
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="
                            filters.includes('is_test') ? 'danger' : 'success'
                        "
                        class="custom-switch"
                    ></v-switch>
                </div> -->
            </div>
            <div class="mt-3">
                <div class="d-flex align-center flex-wrap">
                    <div class="custom-label text-black">Exclude Fake FTD</div>
                    <v-radio-group v-model="excludeFakeFTD" inline hide-details>
                        <v-radio label="All" :value="true"></v-radio>
                        <v-radio label="Only" :value="false"></v-radio>
                    </v-radio-group>
                </div>
                <!-- <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters"
                        value="exclude_fake_FTD"
                        :label="
                            filters.includes('exclude_fake_FTD')
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="
                            filters.includes('exclude_fake_FTD')
                                ? 'danger'
                                : 'success'
                        "
                        class="custom-switch"
                    ></v-switch>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import DateRangeCom from "@/components/global/dateRangeCom.vue";
import { mapActions, mapState } from "vuex";
import { fetchFilterData } from "./service";
import { startOfToday, startOfDay, endOfDay, format } from "date-fns";
export default {
    data() {
        return {
            startOfToday,
            format,
            dateRange: [],
            menu: false,
            startDate: "",
            endDate: "",
            startTime: "",
            endTime: "",
            last24Hours: false,
            insideSelectedHourRange: false,
            createdInsideDateRange: false,
            compareWithPast: false,
            excludeFakeFTD: true,
            isTest: false,
            // list of selected value here
            selectedPublishers: [],
            selectedCountries: [],
            selectedAdvertisers: [],
            selectedStatuses: [],
            selectedCustomParam1: [],
            selectedCustomParam2: [],
            selectedCustomParam3: [],
            selectedCustomParam4: [],
            selectedCustomParam5: [],
            selectedPublisherStatus: [],
            selectedOffer: [],
            selectedDistribution: [],
            selectedFunnel: [],
            // list value here
            publishers: [],
            countries: [],
            advertisers: [],
            statuses: [],
            custom_param_1: [],
            custom_param_2: [],
            custom_param_3: [],
            custom_param_4: [],
            custom_param_5: [],
            publisher_status: [],
            offer: [],
            distribution: [],
            funnel: [],
            filters: [],
        };
    },
    computed: {
        ...mapState(["hideFilterOptions"]),
    },
    watch: {
        dateRange: function (newVal) {
            if (newVal) {
                this.startDate = newVal[0]?.split(" ")[0];
                this.endDate = newVal[1]?.split(" ")[0];
                this.startTime = newVal[0]?.split(" ")[1];
                this.endTime = newVal[1]?.split(" ")[1];
            }
        },
    },

    components: {
        DateRangeCom,
    },
    async mounted() {
        this.dateRange = [
            format(startOfToday(new Date()), "yyyy-MM-dd HH:mm"),
            format(new Date(), "yyyy-MM-dd HH:mm"),
        ];
        await this.fetchFiltersValues();
        if (this.$route.query.filters) {
            const decodedFilters = JSON.parse(
                decodeURIComponent(this.$route.query.filters),
            );
            console.log("decodedFilters", decodedFilters);
            if (Object.keys(decodedFilters).length > 0) {
                await this.setQueryToFilters(decodedFilters);
            }
        }
        this.applyFilters();
    },
    methods: {
        ...mapActions(["setFilterData"]),
        async setQueryToFilters(filterData) {
            this.filters = filterData?.excludes ?? [];
            this.last24Hours = filterData?.last_24_hours ?? false;
            this.insideSelectedHourRange =
                filterData?.inside_selected_hour_range ?? false;
            this.createdInsideDateRange =
                filterData?.created_inside_date_range ?? false;
            this.compareWithPast = filterData?.compare_with_past ?? false;
            this.isTest = filterData?.is_test ?? false;
            this.excludeFakeFTD = filterData?.exclude_fake_FTD ?? true;
            this.selectedPublishers = filterData?.publisher_id ?? [];
            // ? [Number(filterData?.affiliate_id)]
            // : [];
            this.selectedAdvertisers = filterData?.advertiser_id ?? [];
            // ? [Number(filterData?.advertiser_id)]
            // : [];
            this.selectedCountries = filterData?.country_id ?? [];
            // ? [Number(filterData?.country_id)]
            // : [];
            this.selectedStatuses = filterData?.brand_status ?? [];
            // ? [filterData?.brand_status]
            // : [];
            this.selectedCustomParam1 = filterData?.custom_param_1 ?? [];
            // ? [filterData?.custom_param_1]
            // : [];
            this.selectedCustomParam2 = filterData?.custom_param_2 ?? [];
            // ? [filterData?.custom_param_2]
            // : [];
            this.selectedCustomParam3 = filterData?.custom_param_3 ?? [];
            // ? [filterData?.custom_param_3]
            // : [];
            this.selectedCustomParam4 = filterData?.custom_param_4 ?? [];
            // ? [filterData?.custom_param_4]
            // : [];
            this.selectedCustomParam5 = filterData?.custom_param_5 ?? [];
            // ? [filterData?.custom_param_5]
            // : [];
            this.selectedPublisherStatus = filterData?.publisher_status ?? [];
            // ? [filterData?.affiliate_sale_status]
            // : [];
            this.selectedDistribution = filterData?.dis_id ?? [];
            // ? [filterData?.distribution]
            // : [];
            this.selectedFunnel = filterData?.funnel ?? [];
            // ? [filterData?.funnel]
            // : [];
            this.dateRange = filterData.created_at
                ? [
                      format(
                          startOfDay(new Date(filterData.created_at)),
                          "yyyy-MM-dd HH:mm",
                      ),
                      format(
                          endOfDay(new Date(filterData.created_at)),
                          "yyyy-MM-dd HH:mm",
                      ),
                  ]
                : [
                      `${filterData.start_date} ${filterData.start_time}`,
                      `${filterData.end_date} ${filterData.end_time}`,
                  ];
            this.selectedOffer = filterData?.offer ?? [];
            // ? [filterData?.offer]
            // : [];
        },
        async fetchFiltersValues() {
            const response = await fetchFilterData();
            if (!response) return;
            this.advertisers = response?.advertisers || [];
            this.countries = response?.countries || [];
            this.statuses = response?.statuses || [];
            this.publishers = response?.publishers || [];
            this.custom_param_1 = response?.custom_param_1 || [];
            this.custom_param_2 = response?.custom_param_2 || [];
            this.custom_param_3 = response?.custom_param_3 || [];
            this.custom_param_4 = response?.custom_param_4 || [];
            this.custom_param_5 = response?.custom_param_5 || [];
            this.publisher_status = response?.publisher_status || [];
            this.offer = response?.offer || [];
            this.distribution = response?.dis_id || [];
            this.funnel = response?.funnel || [];
            // [ "2024-10-25 00:00:00", "2024-10-25 04:06:25" ]
            // start
            // this.startDate = this.dateRange[0]?.split(" ")[0];
            // this.endDate = this.dateRange[1]?.split(" ")[0];
            // this.startTime = this.dateRange[0]?.split(" ")[1];
            // this.endTime = this.dateRange[1]?.split(" ")[1];
            // end
            // this.selectedCountries = response?.countries?.map(c => c.id) || [];
            // this.selectedPublishers = response?.publishers?.map(p => p.id) || [];
            // this.selectedAdvertisers = response?.advertisers?.map(a => a.id) || [];
            // this.selectedStatuses = response?.statuses?.map(s => s.brand_status) || [];
        },
        resetFilters() {
            this.last24Hours = false;
            this.insideSelectedHourRange = false;
            this.createdInsideDateRange = false;
            this.compareWithPast = false;
            this.selectedPublishers = [];
            this.selectedCountries = [];
            this.selectedAdvertisers = [];
            this.selectedStatuses = [];
            this.dateRange = [
                format(startOfToday(new Date()), "yyyy-MM-dd HH:mm"),
                format(new Date(), "yyyy-MM-dd HH:mm"),
            ];
            this.startDate = startOfToday(new Date());
            this.endDate = new Date();
            this.selectedCustomParam1 = [];
            this.selectedCustomParam2 = [];
            this.selectedCustomParam3 = [];
            this.selectedCustomParam4 = [];
            this.selectedCustomParam5 = [];
            this.selectedPublisherStatus = [];
            this.selectedOffer = [];
            this.selectedDistribution = [];
            this.selectedFunnel = [];
            this.filters = [];
            this.isTest = false;
            this.excludeFakeFTD = true;
        },
        async applyFilters() {
            const payload = {
                exclude_fake_FTD: this.excludeFakeFTD,
                is_test: this.isTest,
                last_24_hours: this.last24Hours,
                inside_selected_hour_range: this.insideSelectedHourRange,
                created_inside_date_range: this.createdInsideDateRange,
                compare_with_past: this.compareWithPast,
                start_date: this.startDate || null,
                end_date: this.endDate || null,
                start_time: this.startTime || null,
                end_time: this.endTime || null,
                country_id: this.selectedCountries?.length
                    ? this.selectedCountries
                    : null,
                affiliate_id: this.selectedPublishers?.length
                    ? this.selectedPublishers
                    : null,
                advertiser_id: this.selectedAdvertisers?.length
                    ? this.selectedAdvertisers
                    : null,
                brand_status: this.selectedStatuses?.length
                    ? this.selectedStatuses
                    : null,
                custom_param_1: this.selectedCustomParam1?.length
                    ? this.selectedCustomParam1
                    : null,
                custom_param_2: this.selectedCustomParam2?.length
                    ? this.selectedCustomParam2
                    : null,
                custom_param_3: this.selectedCustomParam3?.length
                    ? this.selectedCustomParam3
                    : null,
                custom_param_4: this.selectedCustomParam4?.length
                    ? this.selectedCustomParam4
                    : null,
                custom_param_5: this.selectedCustomParam5?.length
                    ? this.selectedCustomParam5
                    : null,
                publisher_status: this.selectedPublisherStatus?.length
                    ? this.selectedPublisherStatus
                    : null,
                offer: this.selectedOffer?.length ? this.selectedOffer : null,
                dis_id: this.selectedDistribution?.length
                    ? this.selectedDistribution
                    : null,
                funnel: this.selectedFunnel?.length
                    ? this.selectedFunnel
                    : null,
                excludes: this.filters,
            };

            console.log(
                "🚀 ~ file: FilterComponent.vue:547 ~ applyFilters ~ payload:",
                payload,
            );
            await this.setFilterData(payload);
            this.$emit("applyFilters", payload);
        },
    },
};
</script>
<style></style>
<style scoped>
.custom-label {
    font-weight: 500;
    font-size: 13px;
    color: #212121;
}
.custom-switch {
    padding-left: 10px;
}
.custom-switch >>> .v-label {
    font-size: 12px !important;
    font-weight: 600;
    line-height: 14px;
}
.custom-switch >>> .v-switch__track {
    height: 20px;
    min-width: 40px;
    border: 1px solid #989898;
    background-color: rgb(219 219 219);
}
.custom-switch >>> .v-switch__thumb {
    box-shadow: none !important;
    background-color: #a5a5a5;
    height: 14px;
    width: 14px;
}
</style>
