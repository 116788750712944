<template>
    <v-container fluid class="pt-0" id="content-layout">
        <v-row >
            <v-col cols="12" class="d-flex justify-end pt-1">
                <StandardBtn
                    type="custom"
                    custom-icon="mdi-filter"
                    custom-color="indigo-darken-1"
                    @click="showFilter = !showFilter"
                    text
                >
                    {{ showFilter ? "Hide filter" : "Show filter" }}
                </StandardBtn>
                </v-col>

        </v-row>
    
        <v-row class="leads-view-content ">
            <v-col v-if="showFilter" class="col1 py-0 " v-show="showFilter">
                <!-- filter slot -->
                <slot name="filter" />
            </v-col>

            <!-- content -->
            <v-col class="col2 py-0 px-6">
                <slot />
            </v-col> 
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "ContentLayout",
    data() {
        return {
            showFilter: true,
        };
    }
    
};
</script>

<style>
#main-container:has(#content-layout){
    background: rgba(var(--v-theme-surface-light),0.5);
    
}
</style> 
<style scoped>
@media (min-width:960px) {
    
    .leads-view-content .col1{
        width: 30% !important;
        flex-shrink: 0 !important;
        min-width: 24rem;
        max-width: 24rem;
    }
    .leads-view-content .col2{
        width: 40% !important;
        flex-grow: 1 !important;
    }
}
 @media (min-width:1600px) {
   .leads-view-content .col2{
        width: 70% !important;
   }
   
   
    
} 
 @media (min-width:1900px) {
    .leads-view-content .col1{
        min-width: 26rem;
        max-width: 26rem;
    }
 }



</style>
