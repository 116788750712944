<template>
<ContentLayout>
    <template #filter>
        <FilterComponent class="elevation-3" @apply-filters="handleApplyFilters" />
    </template>
    <template #default>
        <v-row class="">
            <!-- tabs -->
            <v-col cols="12" class="px-0 pb-0" >
                <v-tabs
                    v-model="tab"
                    show-arrows
                    color="indigo-darken-2"
                    @update:model-value="handleTabChange"
                >
                    <v-tab value="global" class="text-none"
                        ><v-icon icon="mdi-chart-areaspline" class="mr-2" />
                        Global</v-tab
                    >
                    <v-tab value="advertiser_id" class="text-none"
                        ><v-icon icon="mdi-account-tie" class="mr-2" />
                        Advertiser</v-tab
                    >
                    <v-tab value="country_id" class="text-none"
                        ><v-icon icon="mdi-earth" class="mr-2" /> Countries</v-tab
                    >
                    <v-tab value="offer" class="text-none"
                        ><v-icon icon="mdi-application" class="mr-2" />
                        Offers</v-tab
                    >
                    <v-tab value="affiliate_id" class="text-none"
                        ><v-icon icon="mdi-clock-time-four" class="mr-2" />
                        Publisher</v-tab
                    >
                    <v-tab value="created_at" class="text-none"
                        ><v-icon icon="mdi-calendar-range" class="mr-2" />
                        Daily</v-tab
                    >
                    <v-tab value="brand_status" class="text-none"
                        ><v-icon icon="mdi-tag" class="mr-2" /> Statuses</v-tab
                    >
                    <v-tab value="details" class="text-none"
                        ><v-icon icon="mdi-tune" class="mr-2" /> Details</v-tab
                    >
                </v-tabs>
            </v-col>   
            <v-container fluid class="px-0 bg-white rounded-2  py-3 elevation-3">
                <v-row class="p-3">
                    <!-- dropdown col -->
                    <v-col v-if="tab !== 'global'" cols="12" class="d-flex" >
                        <v-spacer></v-spacer>
                        <div class="dropdown " ref="dropdown">
                            <!-- <button
                                class="btn-shadow btn btn-dark btn-sm rounded dense mb-4"
                                @click="toggleDropdown"
                            >
                                Columns
                            </button> -->
                            <div class="">
                                <v-btn 
                                    variant="outlined"
                                    color="#303f9f"
                                    class="rounded"
                                    @click="toggleDropdown"
                                    
                                >
                                    <v-icon>mdi-table</v-icon>
                                </v-btn>
                            </div>
                            <div
                                v-show="dropdownOpen"
                                class="dropdown-content"
                                style="margin: 8px 0 0 -185px; z-index: 11; max-width: 350px; min-width: 250px"
                            >
                                <div class="mb-4 ">Visible Columns</div>
                                <div style="max-height: 300px; overflow-y: auto">
                                    <!-- <template v-for="col in allColumns" :key="col.field"> -->
                                    <!-- tag="transition-group" -->
                                    <draggableElement v-model="tableColumns" item-key="value">
                                        <template #item="{ element: col }">
                                            <div
                                                class="d-flex align-center justify-space-between"
                                            >
                                                <label>
                                                    <div
                                                        class="d-flex align-center gap-2 cursor-pointer"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            :checked="col.visible"
                                                            @change="
                                                                () =>
                                                                    toggleColumnVisibility(
                                                                        col,
                                                                    )
                                                            "
                                                        />
                                                        {{ col.title }}
                                                    </div>
                                                </label>
                                                <i class="mdi mdi-drag cursor-move"></i>
                                            </div>
                                        </template>
                                    </draggableElement>
                                    <!-- </template> -->
                                </div>
                                <div class="mt-4">
                                    <StandardBtn
                                        text
                                        :icon="false"
                                        @click="setDefaultColumns"
                                    >
                                        set as default
                                    </StandardBtn>
                                </div>
                                
                            </div>
                        </div>
                    </v-col>

                    <!-- content col -->
                    <v-col cols="12" class="pt-0 custom-table-container">
                        <template v-if="tab == 'global'">
                            <GlobalDrillDown :loading="loading" :filters="filterData" />
                        </template>
                        <template v-else-if="tab == 'advertiser_id'">
                            <NestedTable
                                :isRoot="true"
                                :loading="loading"
                                :columns="visibleColumns"
                                :rows="rows"
                                :drill_down_list="drillDownList"
                                :filters="filterData"
                                :tab="tab"
                            />
                        </template>
                        <template v-else-if="tab == 'country_id'">
                            <NestedTable
                                :isRoot="true"
                                :loading="loading"
                                :columns="visibleColumns"
                                :rows="rows"
                                :drill_down_list="drillDownList"
                                :filters="filterData"
                                :tab="tab"
                            />
                        </template>
                        <template v-else-if="tab == 'offer'">
                            <NestedTable
                                :isRoot="true"
                                :loading="loading"
                                :columns="visibleColumns"
                                :rows="rows"
                                :drill_down_list="drillDownList"
                                :filters="filterData"
                                :tab="tab"
                            />
                        </template>
                        <template v-else-if="tab == 'affiliate_id'">
                            <NestedTable
                                :isRoot="true"
                                :loading="loading"
                                :columns="visibleColumns"
                                :rows="rows"
                                :drill_down_list="drillDownList"
                                :filters="filterData"
                                :tab="tab"
                            />
                        </template>
                        <template v-else-if="tab == 'created_at'">
                            <NestedTable
                                :isRoot="true"
                                :loading="loading"
                                :columns="visibleColumns"
                                :rows="rows"
                                :drill_down_list="drillDownList"
                                :filters="filterData"
                                :tab="tab"
                            />
                        </template>
                        <template v-else-if="tab == 'brand_status'">
                            <NestedTable
                                :isRoot="true"
                                :loading="loading"
                                :columns="visibleColumns"
                                :rows="rows"
                                :drill_down_list="drillDownList"
                                :filters="filterData"
                                :tab="tab"
                            />
                        </template>
                    </v-col>
                </v-row>
            </v-container>
        </v-row>
    </template>
</ContentLayout>
</template>

<script>
import { fetchLeadPivot } from "./service";
import FilterComponent from "./FilterComponent.vue";
import NestedTable from "./NestedTable.vue";
import GlobalDrillDown from "./GlobalDrillDown.vue";
import { mapActions } from "vuex";
import ContentLayout from "@/layout/ContentLayout.vue";
import Swal from "sweetalert2";
import {getDefaultColumns , setDefaultColumnsReq} from "./service";
export default {
    components: {
        FilterComponent,
        NestedTable,
        GlobalDrillDown,
        ContentLayout
    },
   
    data() {
        return {
            showFilter: true,
            tab: "global",
            columns: [],
            rows: [],
            drillDownList: [],
            filterData: {},
            loading: false,

            //--------new-----------
            tableColumns:[],
            dropdownOpen:false,
            allReportsDefaultColumns:null
        };
    },
    methods: {
        ...mapActions(["setHideFilterOptions"]),
        async handleApplyFilters(filterData) {
            this.filterData = filterData;
            if (this.tab === "global") return;
            const payload = {
                ...filterData,
                group_by: this.tab,
                parents: [`${this.tab}`],
            };
            this.filterData = payload;
            await this.fetchParentData(payload);
        },
        handleHideFilterOptions(tab) {
            if (["global", "created_at"].includes(tab)) {
                this.setHideFilterOptions(["compare_with_past"]);
            } else {
                this.setHideFilterOptions([]);
            }
        },
        async handleTabChange(tab) {
            this.handleHideFilterOptions(tab);
            if (tab === "details") {
                this.$router.push({
                    name: "LeadsView",
                    query: {
                        filters: encodeURIComponent(
                            JSON.stringify(this.filterData),
                        ),
                    },
                });
                return;
            }
            this.columns = [];
            this.rows = [];
            this.drillDownList = [];
            if (tab === "global") return;
            const payload = {
                ...this.filterData,
                group_by: tab,
                parents: [`${tab}`],
            };
            this.filterData = payload;
            await this.fetchParentData(payload);
        },
        genrateRows(rows) {
            return rows?.map((row, index) => {
                return {
                    ...row,
                    action: "",
                    id: index + 1,
                    showNestedTable: false,
                };
            });
        },
        generateColumns(headers) {
            let columns = headers?.map((header) => {
                const title = header
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ");
                return {
                    title: title,
                    key: header,
                };
            });
            columns.splice(1, 0, { title: "", key: "action" });
            return columns;
        },
        generateDrillDownList(list) {
            return Object.entries(list)?.map(([id, title]) => ({ id, title }));
        },
        async fetchParentData(payload) {
            this.loading = true;
            if (!payload) return;
            const response = await fetchLeadPivot(payload);
            if (!response) return;
            this.rows = this.genrateRows(response?.data || []) || [];
            if (this.rows.length > 0) {
                this.rows.push({
                    ...response?.totals,
                    notAction: true,
                    [response?.headers[0]]: "Total",
                });
            }
            this.columns = this.generateColumns(response?.headers || []) || [];
            this.drillDownList =
                this.generateDrillDownList(
                    response?.next_group_by_drilldown_enum || {},
                ) || [];
            this.loading = false;
            return true;
        },

        //-------------------
        toggleColumnVisibility(field) {
            const column = this.tableColumns.find((col) => col.key === field.key);
            if (column) {
                column.visible = !column.visible;
            }
        },
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        },

        async fetchDefaultColumns() {
            const defaultColumns  = await getDefaultColumns({
                user_id: this.$store.getters.getUser.user_id,
            });
            this.allReportsDefaultColumns = JSON.parse(
                JSON.stringify(defaultColumns["reports_columns"]),
            );
        },
        async setDefaultColumns() {
            const payload = {
                user_id: this.$store.getters.getUser.user_id,
                extras: {
                    reports_columns:{
                        [`reports_${this.tabName}_columns`]:this.tableColumns
                    }
                },
            };

            const response = await setDefaultColumnsReq(payload);
            this.allReportsDefaultColumns[`reports_${this.tabName}_columns`] = this.tableColumns

            if (response) {
                Swal.fire({
                    icon: "success",
                    title: "Default Columns Set Successfully",
                    // text: response.message,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to set default columns. Please try again.",
                });
            }
        },

        handleClickOutside(event) {
            if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
                this.dropdownOpen = false;
            }
        }
    },
    watch: {
        //--------new-----------
        columns: {
            handler(value) {
                if(value){
                    this.tableColumns = JSON.parse(JSON.stringify(value))
                    this.tableColumns.map((col) =>{
                        col.visible= true
                        col.sortable= true
                    });
                    if(this.allReportsDefaultColumns?.[`reports_${this.tabName}_columns`]){

                        const defaults = JSON.parse(
                             JSON.stringify(this.allReportsDefaultColumns[`reports_${this.tabName}_columns`]),
                         );
     
                        this.tableColumns = this.tableColumns.map(item => {
                             // Find the item with the same id in the updated array
                             const updatedItem = defaults.find(update => update.key === item.key);
     
                             // If there's an update for this id, use the new value
                             if (updatedItem) {
                                 return { ...item, ...updatedItem};
                             }
     
                             // Otherwise, return the original item
                             return item;
                        });
                        
                    }

                }
            },
            immediate: true
        }
    },
    computed: {
        //------new-------------
        visibleColumns ()  {
            return this.tableColumns.filter((col) => col.visible);
        },
        tabName() {
            if (this.tab === 'global') {
                return 'global';
            } else if (this.tab === 'advertiser_id') {
                return 'advertiser';
            } else if (this.tab === 'country_id') {
                return 'country';
            } else if (this.tab === 'offer') {
                return 'offer';
            } else if (this.tab === 'affiliate_id') {
                return 'affiliate';
            } else if (this.tab === 'created_at') {
                return 'created_at';
            } else if (this.tab === 'brand_status') {
                return 'brand_status';
            } else {
                return '';  // Default case if none of the conditions match
            }
        }
    },
    async mounted(){
        document.addEventListener("click", this.handleClickOutside);
        this.tab = this.$route.query.tab || "global";
        this.handleHideFilterOptions(this.tab);
        await this.fetchDefaultColumns()
    },
    beforeUnmount() {
        this.setHideFilterOptions([]);
        document.removeEventListener("click", this.handleClickOutside);
    },
};
</script>

<style scoped>

 /*------------------tabs-----------------------*/
::v-deep .v-tab--selected {
  background-color: white; /* Custom background color */
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  overflow: hidden;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
}



/*---------dropdown---------*/
.dropdown {
    position: relative;
    display: inline-block;
}
.dropdown-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}
.dropdown-button:hover {
    background-color: #45a049;
}
.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 10px;
}
.dropdown-content label {
    display: block;
    padding: 5px 0;
}
.dropdown-content label:hover {
    background-color: #f1f1f1;
}


</style>
