<template>
<ContentLayout>
    <template #filter>
        <FilterComponent class="elevation-3" @apply-filters="handleApplyFilters" />
    </template>
    <template #default>
        <v-row class="">
            <!-- tabs -->
            <v-col cols="12" class="px-0 pb-0" >
                <v-tabs
                    v-model="tab"
                    show-arrows
                    color="indigo-darken-2"
                    @update:model-value="handleTabChange"
                >
                    <v-tab value="global" class="text-none"
                        ><v-icon icon="mdi-chart-areaspline" class="mr-2" />
                        Global</v-tab
                    >
                    <v-tab value="advertiser_id" class="text-none"
                        ><v-icon icon="mdi-account-tie" class="mr-2" />
                        Advertiser</v-tab
                    >
                    <v-tab value="country_id" class="text-none"
                        ><v-icon icon="mdi-earth" class="mr-2" /> Countries</v-tab
                    >
                    <v-tab value="offer" class="text-none"
                        ><v-icon icon="mdi-application" class="mr-2" />
                        Offers</v-tab
                    >
                    <v-tab value="affiliate_id" class="text-none"
                        ><v-icon icon="mdi-clock-time-four" class="mr-2" />
                        Publisher</v-tab
                    >
                    <v-tab value="created_at" class="text-none"
                        ><v-icon icon="mdi-calendar-range" class="mr-2" />
                        Daily</v-tab
                    >
                    <v-tab value="brand_status" class="text-none"
                        ><v-icon icon="mdi-tag" class="mr-2" /> Statuses</v-tab
                    >
                    <v-tab value="details" class="text-none"
                        ><v-icon icon="mdi-tune" class="mr-2" /> Details</v-tab
                    >
                </v-tabs>
            </v-col>   
            <v-container fluid class="px-0 bg-white rounded-2  py-3 elevation-3">
                <div class="p-3 custom-table-container">
                    <template v-if="tab == 'global'">
                        <GlobalDrillDown :loading="loading" :filters="filterData" />
                    </template>
                    <template v-else-if="tab == 'advertiser_id'">
                        <NestedTable
                            :isRoot="true"
                            :loading="loading"
                            :columns="columns"
                            :rows="rows"
                            :drill_down_list="drillDownList"
                            :filters="filterData"
                            :tab="tab"
                        />
                    </template>
                    <template v-else-if="tab == 'country_id'">
                        <NestedTable
                            :isRoot="true"
                            :loading="loading"
                            :columns="columns"
                            :rows="rows"
                            :drill_down_list="drillDownList"
                            :filters="filterData"
                            :tab="tab"
                        />
                    </template>
                    <template v-else-if="tab == 'offer'">
                        <NestedTable
                            :isRoot="true"
                            :loading="loading"
                            :columns="columns"
                            :rows="rows"
                            :drill_down_list="drillDownList"
                            :filters="filterData"
                            :tab="tab"
                        />
                    </template>
                    <template v-else-if="tab == 'affiliate_id'">
                        <NestedTable
                            :isRoot="true"
                            :loading="loading"
                            :columns="columns"
                            :rows="rows"
                            :drill_down_list="drillDownList"
                            :filters="filterData"
                            :tab="tab"
                        />
                    </template>
                    <template v-else-if="tab == 'created_at'">
                        <NestedTable
                            :isRoot="true"
                            :loading="loading"
                            :columns="columns"
                            :rows="rows"
                            :drill_down_list="drillDownList"
                            :filters="filterData"
                            :tab="tab"
                        />
                    </template>
                    <template v-else-if="tab == 'brand_status'">
                        <NestedTable
                            :isRoot="true"
                            :loading="loading"
                            :columns="columns"
                            :rows="rows"
                            :drill_down_list="drillDownList"
                            :filters="filterData"
                            :tab="tab"
                        />
                    </template>
                </div>
            </v-container>
        </v-row>
    </template>
</ContentLayout>
</template>

<script>
import { fetchLeadPivot } from "./service";
import FilterComponent from "./FilterComponent.vue";
import NestedTable from "./NestedTable.vue";
import GlobalDrillDown from "./GlobalDrillDown.vue";
import { mapActions } from "vuex";
import ContentLayout from "@/layout/ContentLayout.vue";

export default {
    components: {
        FilterComponent,
        NestedTable,
        GlobalDrillDown,
        ContentLayout
    },
    mounted() {
        this.tab = this.$route.query.tab || "global";
        this.handleHideFilterOptions(this.tab);
    },
    beforeUnmount() {
        this.setHideFilterOptions([]);
    },
    data() {
        return {
            showFilter: true,
            tab: "global",
            columns: [],
            rows: [],
            drillDownList: [],
            filterData: {},
            loading: false,
        };
    },
    methods: {
        ...mapActions(["setHideFilterOptions"]),
        async handleApplyFilters(filterData) {
            this.filterData = filterData;
            if (this.tab === "global") return;
            const payload = {
                ...filterData,
                group_by: this.tab,
                parents: [`${this.tab}`],
            };
            this.filterData = payload;
            await this.fetchParentData(payload);
        },
        handleHideFilterOptions(tab) {
            if (["global", "created_at"].includes(tab)) {
                this.setHideFilterOptions(["compare_with_past"]);
            } else {
                this.setHideFilterOptions([]);
            }
        },
        async handleTabChange(tab) {
            this.handleHideFilterOptions(tab);
            if (tab === "details") {
                this.$router.push({
                    name: "LeadsView",
                    query: {
                        filters: encodeURIComponent(
                            JSON.stringify(this.filterData),
                        ),
                    },
                });
                return;
            }
            this.columns = [];
            this.rows = [];
            this.drillDownList = [];
            if (tab === "global") return;
            const payload = {
                ...this.filterData,
                group_by: tab,
                parents: [`${tab}`],
            };
            this.filterData = payload;
            await this.fetchParentData(payload);
        },
        genrateRows(rows) {
            return rows?.map((row, index) => {
                return {
                    ...row,
                    action: "",
                    id: index + 1,
                    showNestedTable: false,
                };
            });
        },
        generateColumns(headers) {
            let columns = headers?.map((header) => {
                const title = header
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ");
                return {
                    title: title,
                    key: header,
                };
            });
            columns.splice(1, 0, { title: "", key: "action" });
            return columns;
        },
        generateDrillDownList(list) {
            return Object.entries(list)?.map(([id, title]) => ({ id, title }));
        },
        async fetchParentData(payload) {
            this.loading = true;
            if (!payload) return;
            const response = await fetchLeadPivot(payload);
            if (!response) return;
            this.rows = this.genrateRows(response?.data || []) || [];
            if (this.rows.length > 0) {
                this.rows.push({
                    ...response?.totals,
                    notAction: true,
                    [response?.headers[0]]: "Total",
                });
            }
            this.columns = this.generateColumns(response?.headers || []) || [];
            this.drillDownList =
                this.generateDrillDownList(
                    response?.next_group_by_drilldown_enum || {},
                ) || [];
            this.loading = false;
            return true;
        },
    },
};
</script>

<style scoped>

 /*------------------tabs-----------------------*/
::v-deep .v-tab--selected {
  background-color: white; /* Custom background color */
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  overflow: hidden;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.16);
}


/* .custom-table-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    height: calc(100vh - 100px);
    overflow-y: auto;
} */
</style>
