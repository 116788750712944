<template>
<v-row class="py-3">
    <v-container fluid class=" bg-white rounded-2   elevation-3">
        <v-row class="">
            <v-col  cols="12" class="d-flex pb-0" >
                <!-- Dropdown for column visibility -->
                <div class="dropdown" ref="dropdown">
                    <!-- <button
                        class="btn-shadow btn btn-dark btn-sm rounded dense mb-4"
                        @click="toggleDropdown"
                    >
                        Columns
                    </button> -->
                    <div class="mb-4">
                        <v-btn 
                            variant="outlined"
                            color="#303f9f"
                            class="rounded"
                            @click="toggleDropdown"
                            
                        >
                            <v-icon>mdi-table</v-icon>
                        </v-btn>
                        
                    </div>
                    <!-- <div
                        v-show="dropdownOpen"
                        class="dropdown-content"
                        style="margin-top: 0px; z-index: 11"
                    >
                        <label v-for="col in allColumns" :key="col.field">
                            <input
                                type="checkbox"
                                :checked="col.visible"
                                @change="() => toggleColumnVisibility(col.field)"
                            />
                            {{ col.title }}
                        </label>
                    </div> -->
                    <div
                        v-show="dropdownOpen"
                        class="dropdown-content"
                        style="margin-top: 0px; z-index: 11; max-width: 350px; min-width: 250px"
                    >
                        <div class="mb-4">Visible Columns</div>
                        <div style="max-height: 300px; overflow-y: auto">
                            <!-- <template v-for="col in allColumns" :key="col.field"> -->
                            <!-- tag="transition-group" -->
                            <draggableElement v-model="allColumns" item-key="value">
                                <template #item="{ element: col }">
                                    <div
                                        class="d-flex align-center justify-space-between"
                                    >
                                        <label>
                                            <div
                                                class="d-flex align-center gap-2 cursor-pointer"
                                            >
                                                <input
                                                    type="checkbox"
                                                    :checked="col.visible"
                                                    @change="
                                                        () =>
                                                            toggleColumnVisibility(
                                                                col.field,
                                                            )
                                                    "
                                                />
                                                {{ col.title }}
                                            </div>
                                        </label>
                                        <i class="mdi mdi-drag cursor-move"></i>
                                    </div>
                                </template>
                            </draggableElement>
                            <!-- </template> -->
                        </div>
                        <div class="mt-4">
                            <StandardBtn text :icon="false" @click="setDefaultColumns">
                                set as default
                            </StandardBtn>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col  cols="12" class="pt-0"  >

                <!-- DataTable Component -->
                <div class="table-container">
                    <Vue3Datatable
                        @pageChange="handlePageChange"
                        @pageSizeChange="handlePageSizeChange"
                        :paginationInfo="
                            'Showing {0} to {1} of {2} entries' +
                            ` | Page ${page} of ${Math.ceil(rows.length / params.pagesize)}`
                        "
                        style="max-height:calc(100vh - 260px) !important';height:auto;overflow:hidden"
                        :stickyHeader="true"
                        :loading="loading"
                        :columns="visibleColumns"
                        sortColumn="ftd_date_converted_time"
                        sortDirection="desc"
                        :rows="rows"
                        :pageSize="params.pagesize"
                        :pageSizeOptions="[10, 15, 30, 50, 100, 200, 500, 1000]"
                        :sortable="true"
                        skin="bh-table-bordered"
                    >
                
                        <!-- paginationInfo="{0} to {1} of {2}" -->
                        <!-- Advertiser Name -->
                        <template #advertiser_name="{ value }">
                            <a
                                :href="`/edit/advertiser/${value.advertiser_id}`"
                                style="pointer-events: auto; text-decoration: underline"
                            >
                                {{ value.advertiser_name }}
                                ({{ value.advertiser_id }})
                            </a>
                        </template>

                        <!-- Affiliate Name -->
                        <template #affiliate_name="{ value }">
                            <a
                                :href="`/edit/affiliate/${value.affiliate_id}`"
                                style="pointer-events: auto; text-decoration: underline"
                            >
                                {{ value.affiliate_name }}
                                ({{ value.affiliate_id }})
                            </a>
                        </template>

                        <!-- Country -->
                        <template #country_name="{ value }">
                            <CountryInfo :name="value.country_name" />
                        </template>

                        <!-- Conversion Rate -->
                        <template #cr="{ value }"> {{ value.cr }}% </template>

                        <!-- Affiliate Conversion Rate -->
                        <template #aff_cr="{ value }"> {{ value.aff_cr }}% </template>

                        <template #aff_cr_when_approved="{ value }">
                            {{ value.aff_cr_when_approved }}%
                        </template>

                        <!-- Brand Status -->
                        <template #status="{ value }">
                            <span
                                v-if="value.status === 'FTD'"
                                class="badge bg-success"
                            >
                                {{ value.status }}
                            </span>
                            <span
                                v-if="value.status === 'FTD REJECTED'"
                                class="badge badge-info"
                            >
                                {{ value.status }}
                            </span>
                            <span
                                v-if="value.status === 'Pending FTD'"
                                class="badge badge-warning"
                            >
                                {{ value.status }}
                            </span>
                            <span
                                v-if="value.status === 'Submitted'"
                                class="badge badge-secondary"
                            >
                                {{ value.status }}
                            </span>
                            <span
                                v-if="value.status === 'Rejected'"
                                class="badge badge-danger"
                            >
                                {{ value.status }}
                            </span>
                            <span
                                v-if="value.status === 'Accepted'"
                                class="badge badge-primary"
                            >
                                {{ value.status }}
                            </span>
                        </template>

                        <template #late_ftd="{ value }">
                            <span v-if="value.late_ftd === 'Yes'">
                                <div class="badge bg-success dense">
                                    {{ value.late_ftd }}
                                </div>
                            </span>
                            <span v-else>
                                <div class="badge bg-danger dense">
                                    {{ value.late_ftd }}
                                </div>
                            </span>
                        </template>
                        <template #actions="{ value }">
                            <div class="d-flex gap-2">
                                <StandardBtn
                                    type="accept"
                                    @click="acceptLead(value.id)"
                                    v-if="
                                        hasPermission('PENDING_CONVERSIONS_MARK_FTD') &&
                                        value.status !== 'FTD'
                                    "
                                />
                                <StandardBtn
                                    type="reject"
                                    @click="rejectLead(value.id)"
                                    v-if="
                                        hasPermission('PENDING_CONVERSIONS_MARK_FTD') &&
                                        value.status === 'Pending FTD'
                                    "
                                />
                            </div>
                        </template>
                    </Vue3Datatable>
                </div>

            </v-col>
            <div
                class="modal fade"
                id="editModal"
                tabindex="-1"
                aria-labelledby="editModalLabel"
                aria-hidden="true"
                ref="editModal"
            >
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editModalLabel">Edit</h5>
                            <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div class="modal-body">
                            Last lead Status as seen by affiliate:

                            <b>{{ status }}</b>

                            <div class="form-check pb-0">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    id="manualRadio"
                                    value="manual"
                                    v-model="selectedOption"
                                    name="leadStatus"
                                    hide-details
                                />
                                <label class="form-check-label" for="manualRadio"
                                    >Manual</label
                                >
                            </div>

                            <div class="form-check pt-0">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    id="dropdownRadio"
                                    value="dropdown"
                                    v-model="selectedOption"
                                    name="leadStatus"
                                />
                                <label class="form-check-label" for="dropdownRadio"
                                    >Choose from dropdown</label
                                >
                            </div>

                            <!-- Conditionally Display Text Field or Dropdown -->
                            <div v-if="selectedOption === 'manual'">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Enter manual input"
                                    v-model="manualInput"
                                />
                            </div>

                            <div v-if="selectedOption === 'dropdown'">
                                <!-- <select class="form-control" v-model="manualInput">
                                    <option value="" disabled>
                                        Select from dropdown
                                    </option>
                                    <option
                                        v-for="option in dropdownStatuses"
                                        :key="option"
                                        :value="option"
                                    >
                                        {{ option }}
                                    </option>
                                </select> -->
                                <v-autocomplete
                                    v-model="dropdownInput"
                                    :items="dropdownStatuses"
                                    placeholder="Select from dropdown"
                                    item-title="brand_status"
                                    item-value="brand_status"
                                    variant="outlined"
                                    density="compact"
                                    hide-details
                                    clearable
                                >
                                    <template v-slot:selection="{ item }">
                                        <v-chip small>
                                            <span>{{ item.value }}</span>
                                        </v-chip>
                                    </template>
                                    <!-- <template v-slot:selection="{ item, index }">
                                        <span>
                                            <v-chip
                                                v-if="index === 0"
                                                small
                                                style="margin-left: 0px"
                                            >
                                                <span>{{ item.title }}</span>
                                            </v-chip>
                                            <v-chip
                                                v-if="
                                                    index === 1 &&
                                                    selectedPublishers
                                                "
                                                small
                                                class="text-caption"
                                                style="font-size: 11px !important"
                                            >
                                                +{{ selectedPublishers.length - 1 }}
                                            </v-chip>
                                        </span>
                                    </template> -->
                                </v-autocomplete>
                            </div>
                            <p>Are you sure you want to reject the field?</p>
                        </div>

                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                class="btn btn-danger"
                                @click="rejectPendingConversion"
                            >
                                Reject
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </v-row>
    </v-container>
</v-row>
</template>

<style scoped>
.table-container {
    max-height: 75vh;
    /* Set the height you need */
    overflow-y: auto;
    /* Enable vertical scrolling */
    overflow-x: auto;
    /* Enable horizontal scrolling if needed */
}

.status {
    text-align: center;
}

.vue3-datatable {
    width: 100%;
    /* Make sure the table takes the full width of the container */
}

.vue3-datatable thead th {
    position: sticky;
    top: 0;
    /* Stick the header to the top */
    background: white;
    /* Set a background color for better visibility */
    z-index: 1;
    /* Ensure the header is above the table body */
}
</style>

<script>
import {
    ref,
    computed,
    watch,
    onMounted,
    onBeforeUnmount,
    nextTick,
    reactive,
} from "vue";
import { Modal } from "bootstrap";

import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import Swal from "sweetalert2";
import axios from "axios";
import {
    fetchFilterData,
    sendRejectRequest,
    getDefaultColumns,
    setDefaultColumnsReq,
} from "./service";
import { usePermissions } from "@/composables/usePermissions";
import { useStore } from "vuex";

export default {
    name: "DataTableComponent",
    components: {
        Vue3Datatable,
    },
    props: {
        filterData: {
            type: Object,
            default: () => ({}),
        },
        // availableStatuses: [],
    },
    // data() {
    //     return {
    //         selectedOption: "", // This will be either 'manual' or 'dropdown'
    //         manualInput: "", // To hold the value of the manual input
    //         selectedDropdownOption: "", // To hold the value of the dropdown selection
    //     };
    // },

    setup(props) {
        const store = useStore();
        const { hasPermission } = usePermissions();
        const loading = ref(false);
        const apiUrl = computed(() => process.env.VUE_APP_API_URL);

        const params = reactive({
            current_page: 1,
            pagesize: 100,
        });
        const page = ref(1);
        function handlePageChange(value) {
            page.value = value;
        }
        function handlePageSizeChange(value) {
            params.pagesize = value;
        }

        const allColumns = ref([
            {
                field: "email",
                title: "Email",
                visible: true,
                sortable: true,
            },
            {
                field: "advertiser_name",
                title: "Advertiser",
                visible: true,
                sortable: true,
            },
            {
                field: "affiliate_name",
                title: "Publisher",
                visible: true,
                sortable: true,
            },
            {
                field: "country_name",
                title: "Country",
                visible: true,
                sortable: true,
            },
            {
                field: "funnel",
                title: "Funnel",
                visible: false,
                sortable: true,
            },
            {
                field: "offer",
                title: "Offer",
                visible: false,
                sortable: true,
            },
            {
                field: "custom_param_1",
                title: "Custom Param 1",
                visible: false,
                sortable: true,
            },
            {
                field: "custom_param_2",
                title: "Custom Param 2",
                visible: false,
                sortable: true,
            },
            {
                field: "custom_param_3",
                title: "Custom Param 3",
                visible: false,
                sortable: true,
            },
            {
                field: "custom_param_4",
                title: "Custom Param 4",
                visible: false,
                sortable: true,
            },
            {
                field: "custom_param_5",
                title: "Custom Param 5",
                visible: false,
                sortable: true,
            },
            {
                field: "total",
                title: "Total",
                visible: false,
                sortable: true,
            },
            {
                field: "registrations",
                title: "Leads",
                visible: true,
                sortable: true,
            },
            {
                field: "deposits",
                title: "FTDs",
                visible: false,
                sortable: true,
            },
            {
                field: "aff_ftd",
                title: "Aff FTDs",
                visible: true,
                sortable: true,
            },
            {
                field: "revenue",
                title: "Revenue",
                visible: false,
                sortable: true,
            },
            {
                field: "payout",
                title: "Payout",
                visible: false,
                sortable: true,
            },
            {
                field: "profit",
                title: "Profit",
                visible: false,
                sortable: true,
            },
            {
                field: "margin",
                title: "Margin",
                visible: false,
                sortable: true,
            },
            {
                field: "affiliate_sale_status",
                title: "Pub Status",
                visible: false,
                sortable: true,
            },
            {
                field: "cr",
                title: "CR %",
                visible: false,
                sortable: true,
                formatter: (value) => `${value}%`,
            },
            {
                field: "cr_when_approved",
                title: "CR % when Approved",
                visible: false,
                sortable: true,
                formatter: (value) => `${value}%`,
            },
            {
                field: "aff_cr",
                title: "Aff CR %",
                visible: true,
                sortable: true,
                formatter: (value) => `${value}%`,
            },
            {
                field: "aff_cr_when_approved",
                title: "Aff CR % when Approved",
                visible: true,
                sortable: true,
                formatter: (value) => `${value}%`,
            },
            {
                field: "eCPL",
                title: "eCPL",
                visible: false,
                sortable: true,
            },
            {
                field: "AeCPL",
                title: "AeCPL",
                visible: false,
                sortable: true,
            },
            {
                field: "brand_status",
                title: "Advertiser Status",
                visible: false,
                sortable: true,
            },
            {
                field: "advertiser_id",
                title: "Advertiser Id",
                visible: false,
                sortable: true,
            },
            {
                field: "affiliate_id",
                title: "Publisher Id",
                visible: false,
                sortable: true,
            },
            {
                field: "country_id",
                title: "Country Id",
                visible: false,
                sortable: true,
            },

            {
                field: "id",
                title: "ID",
                visible: true,
                sortable: true,
                width: "10px",
            },
            {
                field: "status",
                title: "Status",
                visible: true,
                sortable: true,
            },
            {
                field: "created_at_converted_time",
                title: "Created Date",
                visible: true,
                sortable: true,
            },
            {
                field: "ftd_date_converted_time",
                title: "FTD Date",
                visible: true,
                sortable: true,
            },
            {
                field: "late_ftd",
                title: "Late FTD",
                visible: true,
                sortable: true,
            },
            {
                field: "actions",
                title: "Actions",
                visible: true,
            },
        ]);

        const rows = ref([]);
        const dropdownOpen = ref(false);
        const dropdown = ref(null);
        const editId = ref(null);

        // modal inputs & dropdowns
        const selectedOption = ref("manual"); // This will be either 'manual' or 'dropdown'
        const status = ref(null);
        const manualInput = ref(null); // To hold the value of the manual input
        const dropdownStatuses = ref([]); // To hold the value of the dropdown statuses
        const dropdownInput = ref(null); // To hold the value of the dropdown selection

        function toggleDropdown() {
            dropdownOpen.value = !dropdownOpen.value;
        }

        function acceptLead(id) {
            console.log("Accept Lead: " + id);
            Swal.fire({
                title: "Are you sure?",
                text: `You are about to accept the lead with ID: ${id}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#6861ce",
                cancelButtonColor: "#d33",
                confirmButtonText: "Accept",
                cancelButtonText: "Cancel",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const url = `/api/pending-conversions/action`;

                    // Prepare the request body
                    const requestBody = {
                        lead_id: id,
                        status_override: null,
                        is_accept: true,
                    };

                    try {
                        // Make the POST request with axios
                        const response = await axios.post(url, requestBody, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        });

                        // Check if the response has a message
                        if (response.data && response.data.message) {
                            // Display success alert if the response contains the message
                            Swal.fire({
                                icon: "success",
                                title: "Lead Accepted",
                                text: response.data.message,
                            });
                        } else {
                            throw new Error("Unexpected response format");
                        }
                    } catch (error) {
                        // Handle error and display an error alert
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Failed to accept the lead. Please try again.",
                        });

                        console.error("Failed to accept lead:", error);
                    }
                }
            });
        }
        const editModal = ref(null);
        async function rejectLead(id) {
            try {
                const filterData = await fetchFilterData();
                dropdownStatuses.value = filterData.statuses.filter(
                    (obj) => !!obj.brand_status,
                );
            } catch (error) {
                console.log("Error fetching filter data:", error);
            }
            // console.log("Reject Lead: " + id);
            editId.value = id; // Set the id for the modal
            status.value = rows.value.find(
                (row) => row.id === id,
            )?.affiliate_sale_status;
            nextTick(() => {
                if (editModal.value) {
                    const modalInstance = new Modal(editModal.value);
                    modalInstance.show();
                }
            });
        }

        function toggleColumnVisibility(field) {
            const column = allColumns.value.find((col) => col.field === field);
            if (column) {
                column.visible = !column.visible;
            }
        }

        async function rejectPendingConversion() {
            // console.log("Reject Pending Conversion: " + editId.value);
            const payload = {
                lead_id: editId.value,
                status_override:
                    selectedOption.value.toLowerCase() === "manual"
                        ? manualInput.value
                        : dropdownInput.value,
                is_accept: false,
            };

            try {
                const response = await sendRejectRequest(payload);
                // Close the modal
                if (editModal.value) {
                    const modalInstance = Modal.getInstance(editModal.value);
                    if (modalInstance) {
                        modalInstance.hide();
                    }
                }

                // Update the row with the appropriate value
                const rowIndex = rows.value.findIndex(
                    (row) => row.id === editId.value,
                );
                if (rowIndex !== -1) {
                    rows.value[rowIndex].status = payload.status_override;
                }
                Swal.fire({
                    icon: "success",
                    title: "Lead Rejected",
                    text: response.message,
                });
                console.log("pending conversion rejection successful");
            } catch (error) {
                console.log("Error rejecting pending conversion:", error);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to reject the lead. Please try again.",
                });
            }

            // const column = allColumns.value.find((col) => col.field === field);
            // if (column) {
            //     column.visible = !column.visible;
            // }
        }

        const visibleColumns = computed(() => {
            return allColumns.value.filter((col) => col.visible);
        });

        // const dropdownStatuses = computed(() => {
        //     console.log("Aish: " + props.filterData.availableStatuses);
        //     return props.filterData.availableStatuses;
        // });

        // const filteredRows = computed(() => {
        //     return rows.value.filter((row) => {
        //         return Object.keys(props.filterData.filters || {}).every(
        //             (key) => {
        //                 return (
        //                     !props.filterData.filters[key] ||
        //                     row[key] === props.filterData.filters[key]
        //                 );
        //             },
        //         );
        //     });
        // });

        // async function fetchDataWithFilter(
        //     advertiser_id = "",
        //     countries_id = "",
        //     publisher_id = "",
        //     filter = "",
        //     startDate = "",
        //     endDate = "",
        // ) {
        //     advertiser_id = advertiser_id || null;
        //     countries_id = countries_id || null;
        //     publisher_id = publisher_id || null;
        //     startDate = startDate || null;
        //     endDate = endDate || null;

        //     const requestOptions = {
        //         method: "POST",
        //         headers: {
        //             "Content-Type": "application/json",
        //         },
        //         body: JSON.stringify({
        //             tz_offset: "+03:00",
        //             advertiser_id: advertiser_id, // Adjusted to match the parameters
        //             country_id: countries_id,
        //             publisher_id: publisher_id,
        //             start_date: startDate !== "null" ? startDate : null, // Send null if date is not provided
        //             end_date: endDate !== "null" ? endDate : null,
        //         }),
        //     };

        //     const url = `/api/pending-conversions`;

        //     console.log("URL is", filter, url);
        //     try {
        //         const response = await fetch(url, requestOptions);
        //         if (!response.ok) {
        //             throw new Error("Network response was not ok");
        //         }
        //         const data = await response.json();
        //         rows.value = data;
        //         console.log("hoiiiii", data, requestOptions);
        //     } catch (error) {
        //         console.error("Failed to fetch data:", error);
        //     }
        // }

        // onMounted(async () => {
        //     countries.value = await getCountries();

        //     const today = new Date();

        //     const formatDate = (date) => {
        //         const year = date.getFullYear();
        //         const month = String(date.getMonth() + 1).padStart(2, "0");
        //         const day = String(date.getDate()).padStart(2, "0");
        //         return `${year}-${month}-${day}`;
        //     };

        //     const dayOfWeek = today.getDay();

        //     const startDate = new Date(today);
        //     startDate.setDate(today.getDate() - dayOfWeek);

        //     const endDate = today;

        //     const formattedStartDate = formatDate(startDate);
        //     const formattedEndDate = formatDate(endDate);

        //     fetchDataWithFilter(
        //         null,
        //         null,
        //         null,
        //         null,
        //         formattedStartDate,
        //         formattedEndDate,
        //     );

        //     document.addEventListener("click", handleClickOutside);
        // });

        function handleClickOutside(event) {
            if (dropdown.value && !dropdown.value.contains(event.target)) {
                dropdownOpen.value = false;
            }
        }

        async function setDefaultColumns() {
            const payload = {
                user_id: store.getters.getUser.user_id,
                extras: {
                    pending_conversion_columns: allColumns.value,
                },
            };

            const response = await setDefaultColumnsReq(payload);

            if (response) {
                Swal.fire({
                    icon: "success",
                    title: "Default Columns Set Successfully",
                    // text: response.message,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to set default columns. Please try again.",
                });
            }
        }

        onMounted(async () => {
            document.addEventListener("click", handleClickOutside);
            const defaultColumns = await getDefaultColumns({
                user_id: store.getters.getUser.user_id,
            });
            allColumns.value = JSON.parse(
                JSON.stringify(defaultColumns["pending_conversion_columns"]),
            );
        });

        onBeforeUnmount(() => {
            document.removeEventListener("click", handleClickOutside);
        });

        const fetchRowsByFilter = async (filterData) => {
            try {
                console.log("Filter data:", filterData);
                loading.value = true;
                const response = await axios.post(`/api/pending-conversions`, {
                    tz_offset: "+03:00",
                    last_24_hours: filterData?.last_24_hours ?? false,
                    inside_selected_hour_range:
                        filterData?.inside_selected_hour_range ?? false,
                    created_inside_date_range:
                        filterData?.created_inside_date_range ?? false,
                    compare_with_past: filterData?.compare_with_past ?? false,
                    advertiser_id: filterData?.advertiser_id || null, // Adjusted to match the parameters
                    country_id: filterData?.country_id || null,
                    publisher_id: filterData?.affiliate_id || null,
                    start_date: filterData?.start_date || null, // Send null if date is not provided
                    end_date: filterData?.end_date || null,
                    start_time: filterData?.start_time || null,
                    end_time: filterData?.end_time || null,
                });
                console.log("Updated rows", response.data);
                rows.value = response.data;
            } catch (error) {
                console.error("Failed to fetch data:", error);
            } finally {
                loading.value = false;
            }
        };

        watch(
            () => props.filterData,
            (newValue) => {
                console.log("New value:", newValue);
                fetchRowsByFilter(newValue);
            },
            {
                deep: true,
            },
        );

        // watch(
        //     () => props.filterData,
        //     (newFilterData) => {
        //         const { filters } = newFilterData;
        //         const {
        //             advertiser_id,
        //             countries_id,
        //             publisher_id,
        //             offers,
        //             statuses,
        //             startDate,
        //             endDate,
        //         } = filters;
        //         console.log(
        //             "Filter data received in DataTableComponent:",
        //             advertiser_id,
        //             countries_id,
        //             publisher_id,
        //             offers,
        //             statuses,
        //             filters,
        //         );

        //         fetchDataWithFilter(
        //             advertiser_id,
        //             countries_id,
        //             publisher_id,
        //             offers,
        //             startDate,
        //             endDate,
        //         );
        //         filteredRows.value = rows.value.filter((row) => {
        //             return Object.keys(filters || {}).every((key) => {
        //                 return !filters[key] || row[key] === filters[key];
        //             });
        //         });
        //     },
        //     {
        //         deep: true,
        //     },
        // );

        return {
            hasPermission,
            params,
            page,
            handlePageChange,
            handlePageSizeChange,
            rows,
            allColumns,
            visibleColumns,
            // filteredRows,
            toggleColumnVisibility,
            rejectPendingConversion,
            dropdownOpen,
            dropdown,
            toggleDropdown,
            apiUrl,
            acceptLead,
            rejectLead,
            editModal,
            dropdownStatuses,
            manualInput,
            dropdownInput,
            selectedOption,
            status,
            loading,
            setDefaultColumns,
        };
    },
};
</script>

<style scoped>
::v-deep .bh-table-responsive table  tbody tr:has(td div.bh-skeleton-box) {
    display: none;
}
::v-deep .bh-table-responsive table  tbody tr:has(td div.bh-skeleton-box):first-child {
    display:table-row;
}

/* ::v-deep .bh-table-responsive table> tbody tr:has(td>div.bh-skeleton-box){
    display: none;
    background: red;
} */
/* Change the header background color */
.bh-datatable .bh-table thead th {
    background-color: black;
    /* Change to your desired color */
    color: #fff;
    /* Change to text color */
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropdown-button:hover {
    background-color: #45a049;
}

.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 10px;
}

.dropdown-content label {
    display: block;
    padding: 5px 0;
}

.dropdown-content label:hover {
    background-color: #f1f1f1;
}
</style>
