<template>
    <v-card
        v-if="!qrCodeSection && !otpSection"
        max-width="400"
        class="mx-auto mt-12"
    >
        <v-card-title class="text-center">Login</v-card-title>
        <!-- TODO brand icon -->
        <!-- <v-img :src="logo" max-width="200" class="mx-auto" contain></v-img> -->

        <v-card-text class="mt-4">
            <v-form @submit.prevent="handleLogin">
                <v-text-field
                    prepend-inner-icon="mdi-email"
                    v-model="email"
                    label="Email"
                    required
                    type="email"
                    variant="outlined"
                ></v-text-field>
                <v-text-field
                    prepend-inner-icon="mdi-lock"
                    v-model="password"
                    label="Password"
                    required
                    type="password"
                    variant="outlined"
                ></v-text-field>
                <v-btn type="submit" color="primary" class="w-100">Login</v-btn>
                <div
                    class="text-caption text-danger text-center"
                    v-if="errorMessage"
                >
                    {{ errorMessage }}
                </div>
            </v-form>
        </v-card-text>
    </v-card>

    <v-card v-if="qrCodeSection" max-width="400" class="mx-auto mt-12">
        <v-card-title class="text-center">
            Set up Two-Factor Authentication
        </v-card-title>
        <v-card-subtitle class="text-center">
            Download an authenticator app
            <div class="text-body-2">
                Android, iOS, Blackberry - Google Authenticator
            </div>
            <div class="text-body-2">Windows Phone - Authenticator</div>
        </v-card-subtitle>
        <v-card-text>
            <v-form @submit.prevent="ConfigureCode">
                <div class="text-center">
                    <div>Scan this code with the app</div>
                    <v-img
                        :src="qrCodeImage"
                        max-width="200"
                        class="mx-auto"
                        contain
                    ></v-img>

                    <v-btn
                        @click="showSecretKey = !showSecretKey"
                        variant="text"
                        class="mx-auto"
                        >{{
                            showSecretKey
                                ? "Hide Secret Key"
                                : "Show Secret Key"
                        }}</v-btn
                    >
                    <div
                        class="text-caption text-center mt-2"
                        v-if="showSecretKey"
                    >
                        {{ secretKey }}
                    </div>

                    <div>Enter the 6-digit code generated by the app</div>

                    <v-otp-input
                        v-model="code"
                        length="6"
                        variant="underlined"
                    ></v-otp-input>
                </div>
                <v-btn type="submit" color="primary" class="w-100"
                    >Verify Code</v-btn
                >
                <div
                    class="text-caption text-danger text-center"
                    v-if="errorMessage2"
                >
                    {{ errorMessage2 }}
                </div>
            </v-form>
        </v-card-text>
    </v-card>

    <v-card v-if="otpSection" max-width="400" class="mx-auto mt-12">
        <v-card-title class="text-center">
            Verify with Google Authenticator
        </v-card-title>
        <v-card-subtitle class="text-center">
            {{ email }}
        </v-card-subtitle>
        <v-card-text>
            <v-form @submit.prevent="verifyCode">
                <div class="text-center">
                    <div>
                        Enter the temporary code generated in your Google
                        Authenticator app
                    </div>

                    <v-otp-input
                        v-model="code"
                        length="6"
                        variant="underlined"
                    ></v-otp-input>
                </div>
                <v-btn type="submit" color="primary" class="w-100"
                    >Verify Code</v-btn
                >
                <div
                    class="text-caption text-danger text-center"
                    v-if="errorMessage2"
                >
                    {{ errorMessage2 }}
                </div>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
    data() {
        return {
            logo: require("@/assets/img/logo.jpeg"), // Use require to resolve the logo path
        };
    },
    setup() {
        const router = useRouter();
        const store = useStore();

        const email = ref("");
        const password = ref("");
        const errorMessage = ref("");

        const qrCodeSection = ref(false);
        const qrCodeImage = ref(null);
        const secretKey = ref(null);
        const showSecretKey = ref(false);
        const otpSection = ref(false);

        const code = ref("");
        const errorMessage2 = ref("");

        function parseSVG(response) {
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(response, "image/svg+xml");
            const svgElement = svgDoc.documentElement;
            const svgString = new XMLSerializer().serializeToString(svgElement);
            const svgBase64 = `data:image/svg+xml;base64,${btoa(svgString)}`;
            return svgBase64;
        }

        const authenticateUser = async (data) => {
            // store data in local storage
            await store.dispatch("setUser", data);
            router.push({ name: "DashboardView" });
        };

        const handleLogin = async () => {
            try {
                const response = await axios.post("/api/login", {
                    email: email.value,
                    password: password.value,
                });
                if (response.data.token) {
                    authenticateUser(response.data);
                } else if (
                    response.data.message === "Two factor code required"
                ) {
                    // Two factor code required
                    otpSection.value = true;
                } else {
                    // show qr code section
                    console.log("QR Code Section", response.data);
                    qrCodeSection.value = true;
                    qrCodeImage.value = parseSVG(response.data["QR code"]); // Ensure this is a valid SVG URL or data

                    secretKey.value = response.data["secret"];
                }
                // }
                // console.log(response);
            } catch (error) {
                console.log(error.status);
                if (error.data.error && error.status === 400) {
                    console.log("Error: ", error.data.error);
                    errorMessage.value = error.data.error;
                }
                // if (error.data.message && error.status === 401) {
                //     console.log("Error: ", error.data.message);
                //     // show the otp section

                // }
            }
        };

        const ConfigureCode = async () => {
            console.log("Configure Code");
            try {
                const response = await axios.post(
                    "/api/users/two-factor-configure",
                    {
                        email: email.value,
                        code: code.value,
                    },
                );
                console.log(response);
                authenticateUser(response.data);
            } catch (error) {
                console.log(error);
                errorMessage2.value = error.data.message;
            }
        };

        const verifyCode = async () => {
            console.log("Verify Code");
            try {
                const response = await axios.post(
                    "/api/users/two-factor-verify",
                    {
                        email: email.value,
                        code: code.value,
                    },
                );
                console.log(response);
                authenticateUser(response.data);
            } catch (error) {
                console.log(error);
                errorMessage2.value = error.data.message;
            }
        };

        return {
            handleLogin,
            email,
            password,
            errorMessage,
            qrCodeSection,
            qrCodeImage,
            secretKey,
            showSecretKey,
            ConfigureCode,
            otpSection,
            code,
            errorMessage2,
            verifyCode,
        };
    },
};
</script>

<style scoped>
/* Add any styles if necessary */
</style>
