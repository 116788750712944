<template>
    <v-row class="">
        <!-- tabs -->
        <v-col cols="12" class="px-0 pb-0" >
            <v-tabs
                model-value="details"
                color="indigo-darken-2"
                show-arrows
                @update:model-value="handleTabChange"
            >
                <v-tab value="global" class="text-none"
                    ><v-icon icon="mdi-chart-areaspline" class="mr-2" />
                    Global</v-tab
                >
                <v-tab value="advertiser_id" class="text-none"
                    ><v-icon icon="mdi-account-tie" class="mr-2" />
                    Advertiser</v-tab
                >
                <v-tab value="country_id" class="text-none"
                    ><v-icon icon="mdi-earth" class="mr-2" />
                    Countries</v-tab
                >
                <v-tab value="offer" class="text-none"
                    ><v-icon icon="mdi-application" class="mr-2" />
                    Offers</v-tab
                >
                <v-tab value="affiliate_id" class="text-none"
                    ><v-icon icon="mdi-clock-time-four" class="mr-2" />
                    Publisher</v-tab
                >
                <v-tab value="created_at" class="text-none"
                    ><v-icon icon="mdi-calendar-range" class="mr-2" />
                    Daily</v-tab
                >
                <v-tab value="brand_status" class="text-none"
                    ><v-icon icon="mdi-tag" class="mr-2" /> Statuses</v-tab
                >
                <v-tab
                    value="details"
                    class="text-none "
                    activeColor="#fff"
                    baseColor="#fff"
                    rounded
                    @click="$router.push({ name: 'LeadsView' })"
                    ><v-icon icon="mdi-tune" class="mr-2" /> Details</v-tab
                >
            </v-tabs>
        </v-col>
        <!-- content -->
        <v-container style="min-height:calc(100vh - 200px);" id="content-inner-container" fluid class=" bg-white rounded-2  py-8 elevation-3">
            <v-row class="gap-1">
                <!-- search section 1 column-->
                <v-col cols="12"  >
                    <!-- All Conversions list, ALL Leads -->
                    <v-row >
                        <v-col class="d-flex" :order="$vuetify.display.sm || ($vuetify.display.md && !showFilter) || $vuetify.display.lgAndUp ? 3: ''" >
                            <v-spacer></v-spacer>
                            <div class="dropdown " ref="dropdown">
                                <!-- <button
                                    class="btn-shadow btn btn-dark btn-sm rounded dense mb-4"
                                    @click="toggleDropdown"
                                >
                                    Columns
                                </button> -->
                                <div class="">
                                    <v-btn 
                                        variant="outlined"
                                        color="#303f9f"
                                        class="rounded"
                                        @click="toggleDropdown"
                                        
                                    >
                                        <v-icon>mdi-table</v-icon>
                                    </v-btn>
                                    <!-- <StandardBtn
                                        :text="false"
                                        type="custom"
                                        custom-icon="mdi-table"
                                        @click="toggleDropdown"
                                        
                                    >
                                        Columns
                                    </StandardBtn> -->
                                </div>
                                <div
                                    v-show="dropdownOpen"
                                    class="dropdown-content"
                                    style="margin: 8px 0 0 -185px; z-index: 11; max-width: 350px; min-width: 250px"
                                >
                                    <div class="mb-4 ">Visible Columns</div>
                                    <div style="max-height: 300px; overflow-y: auto">
                                        <!-- <template v-for="col in allColumns" :key="col.field"> -->
                                        <!-- tag="transition-group" -->
                                        <draggableElement v-model="allColumns" item-key="value">
                                            <template #item="{ element: col }">
                                                <div
                                                    class="d-flex align-center justify-space-between"
                                                >
                                                    <label>
                                                        <div
                                                            class="d-flex align-center gap-2 cursor-pointer"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                :checked="col.visible"
                                                                @change="
                                                                    () =>
                                                                        toggleColumnVisibility(
                                                                            col,
                                                                        )
                                                                "
                                                            />
                                                            {{ col.title }}
                                                        </div>
                                                    </label>
                                                    <i class="mdi mdi-drag cursor-move"></i>
                                                </div>
                                            </template>
                                        </draggableElement>
                                        <!-- </template> -->
                                    </div>
                                    <div class="mt-4">
                                        <StandardBtn
                                            text
                                            :icon="false"
                                            @click="setDefaultColumns"
                                        >
                                            set as default
                                        </StandardBtn>
                                    </div>
                                    
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="5" :md="showFilter? 12 : 5" lg="5" xl="4"  >
                            <v-select
                                v-model="selectedConversion"
                                :items="conversions"
                                item-title="name"
                                item-value="name"
                                return-object
                                label="Select Conversion"
                                variant="outlined"
                                density="compact"
                                hide-details
                            >
                            </v-select>
                        </v-col>
                        <!-- :style="$vuetify.display.mdAndUp && 'padding-right:12px;'" -->
                        <v-col cols="12" sm="5"   :md="showFilter? 12 : 5" lg="5" xl="4"  >
                            <v-select
                                v-model="selectedLead"
                                :items="leads"
                                item-title="name"
                                item-value="name"
                                return-object
                                label="Select Lead"
                                variant="outlined"
                                density="compact"
                                hide-details
                            >
                            </v-select>
                        </v-col>

                        
                    </v-row>
                </v-col>
                <!-- divider column-->
                <v-col cols="12" class="" style="padding-top:0;padding-bottom:0;">
                    <v-divider style="margin:0px"></v-divider>
                </v-col>
                <!-- search section 2 column -->
                <v-col cols="12"  >
                    <v-row >
                        <!-- search input -->
                        <v-col cols="12"  :lg="showFilter? 12 : 7" :xl="($vuetify.display.width > 2100) ? 8 : 7"  class="d-flex">
                            <div id="search-input-container"  class="w-100 relative" >
                                <label id="search-input-label">Search</label>
                                <div id="search-input" class="d-flex w-100 overflow-hidden "
                                :class="{'wrapped':$vuetify.display.xs || ($vuetify.display.md && showFilter)}">

                                    <v-btn @click="toggleSearchMultiple"  :variant="searchMultiple ? 'flat' : 'tonal'" color="#303f9f"
                                        style="min-width:unset; "
                                        class="rounded-0  h-100  align-items-center"
                                        >
                                        <v-icon>mdi-selection-search</v-icon>
                                    </v-btn>
                                    <v-divider vertical class="custom-divider" color="#303f9f" :opacity="searchMultiple? 0.75 : 0.25"></v-divider>

                                    <div class="custom-autocomplete-container ">
                                        <!-- Autocomplete -->
                                        <v-autocomplete
                                            :items="searchItems"
                                            item-value="id"
                                            item-title="label"
                                            variant="plain"
                                            hide-details
                                            density="compact"
                                            class="no-borders custom-autocomplete"
                                            v-model="searchId"
                                
                                        />
                                    </div>

                                    <!-- Divider -->
                                    <v-divider vertical class="custom-divider" ></v-divider>
                                    <div class="custom-textarea-container">
                                        <!-- Textarea -->
                                        <v-text-field
                                            placeholder="Search by selected field"
                                            v-model="searchValue"
                                            variant="plain"
                                            density="compact"
                                            hide-details
                                            no-resize
                                            :readonly="searchMultiple"
                                            @click="searchMultiple && openSearchDialog()"
                                            class="no-borders text-input-height custom-textarea"
                                        />
                                    </div>
                                    <div class="d-flex h-100 search-input-btns-container wrapped">
                                        <v-divider vertical class="custom-divider first-divider" color="#303f9f" opacity="0.25"></v-divider>
                                        <v-btn @click="submitSearch({ global: false })" variant="tonal" color="#303f9f" style="min-width:unset; " class="px-4  rounded-0  h-100 d-flex align-items-center">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>

                                        <v-divider vertical class="custom-divider" color="#303f9f" opacity="0.25"></v-divider>
                                        <v-btn @click="submitSearch({ global: true })" variant="tonal" color="#303f9f" style="min-width:unset; " class="px-4   rounded-0  h-100 d-flex align-items-center">
                                            <v-icon>mdi-magnify-plus-outline</v-icon>
                                        </v-btn>


                                        <v-divider vertical class="custom-divider" color="#303f9f" opacity="0.25"></v-divider>
                                        <v-btn @click="clearSearchField()" variant="tonal" color="#303f9f" style="min-width:unset; " class="px-4  rounded-0     h-100 d-flex align-items-center">
                                            <v-icon>mdi mdi-backspace</v-icon>
                                        </v-btn>
                                    </div>

                                </div>
                            </div>
                        </v-col>

                        <!-- selection actions -->
                        <v-col class="d-flex gap-3" :xl="($vuetify.display.width > 2100) ? 4 : 5"  
                        :class="{'flex-column ':$vuetify.display.mdAndDown  }">
                            <v-spacer ></v-spacer>
                            <v-menu class="d-flex  gap-3 flex-shrink-1" style="flex-shrink:1 !important;" >
                                <template v-slot:activator="{ props }">
                                    <!-- <v-btn 
                                    v-if="slectedTableRows.length > 0"
                                        :disabled="slectedTableRows?.length === 0"
                                        @click="slectedTableRows = []"
                                    >
                                        clear
                                        {{ slectedTableRows.length }}  
                                        <span  v-if=" !($vuetify.display.width > 1280 && $vuetify.display.width < 1360  && showFilter)  ">&nbsp;selections</span> 
                                    </v-btn> -->
                                    
                                    <v-btn
                                        append-icon="mdi-chevron-down"
                                        prepend-icon="mdi-check"
                                        color="indigo-darken-2"
                                        v-bind="props"
                                        @click="fetchProgressLogsCount"
                                    >
                                        Apply to selection
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        @click="copyEmailToClipboard"
                                        :disabled="slectedTableRows?.length === 0"
                                    >
                                        <v-list-item-title class="text-uppercase">
                                            Copy emails to clipboard
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="hasPermission('LEADS_OVERRIDE_STATUS')"
                                        @click="openOverrideStatusDialog"
                                        :disabled="slectedTableRows?.length === 0"
                                    >
                                        <v-list-item-title class="text-uppercase">
                                            Override status
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="hasPermission('LEADS_MARK_TEST_LEAD')"
                                        @click="markAsTestLeadsDialog = true"
                                        :disabled="slectedTableRows?.length === 0"
                                    >
                                        <v-list-item-title class="text-uppercase">
                                            Mark as test leads
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="hasPermission('LEADS_MARK_FTD')"
                                        @click="fireFTDDialog = true"
                                        :disabled="slectedTableRows?.length === 0"
                                    >
                                        <v-list-item-title class="text-uppercase">
                                            Fire FTD
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-list-item
                                        v-if="hasPermission('LEADS_INJECTION')"
                                        @click="openManageSelectionDialog"
                                        :disabled="slectedTableRows?.length === 0"
                                    >
                                        <v-list-item-title class="text-uppercase">
                                            Manage selection
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="hasPermission('LEADS_PROGRESS_LOGS')"
                                        @click="openProgressLogsDialog"
                                    >
                                        <v-list-item-title class="text-uppercase">
                                            progress logs
                                            <span
                                                class="badge"
                                                style="background-color: #7e3584"
                                                >{{ progressLogsCount }}</span
                                            >
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <!-- dddddddddd -->
                            <!-- <StandardBtn text size="large" append-icon>  </StandardBtn> -->
                            <!-- :disabled="slectedTableRows?.length == 0" -->
                            <v-btn @click="exportTable('csv')" class=""
                                >export as CSV</v-btn
                            >
                            <!-- <button
                                class="btn-shadow btn btn-dark btn-sm rounded dense mb-4"
                                @click="toggleDropdown"
                            >ddddddddddddddd
                                Columns
                            </button> -->
                        </v-col>
                    </v-row>
                    
                </v-col>

                <!-- table column -->
                <v-col cols="12"  >
                    <v-row >
                        <v-col cols="12"  >
                            <div v-if="slectedTableRows.length > 0" id="clear-selections-container">
                                <span class="fst-italic">{{ slectedTableRows.length }} rows selected - </span>
                                <a class="fst-italic" id="clear-selections"
                                        :disabled="slectedTableRows?.length === 0"
                                        @click="slectedTableRows = []"
                                    >   
                                    clear selections
                                </a>
                            </div>
                        </v-col>
                    </v-row>
                    <!-- DataTable Component -->
                    <v-card>
                        <v-data-table
                            ref="tableElement"
                            :cell-props="setBackgroundColor"
                            density="compact"
                            id="main-lead-table"
                            :fixed-header="true"
                            :fixed-footer="true"
                            :sticky="true"
                            height="auto"
                            color="#f00"
                            v-model:items-per-page="params.pagesize"
                            :headers="visibleColumns"
                            :items="rows"
                            :loading="loading"
                            :items-per-page-options="[10, 15, 30, 50, 100, 200, 500, 1000]"
                            :sort-by="[{ key: 'created_at', order: 'desc' }]"
                            show-select
                            v-model="slectedTableRows"
                            class="elevation-1"
                            @update:modelValue="countSelectedRows"
                            :hide-default-footer="false"
                            @update:itemsPerPage="handlePageSizeChange"
                            @update:page="handlePageChange"
                            
                        >
                        

                            <!-- ------------Headers-------- -->
                            <template v-for="col in allColumns" :key="col.value" #[`header.${col.value}`]="{ column,isSorted, getSortIcon, toggleSort}">
                                <div class="d-flex w-100 justify-content-between align-items-center">
                                    <span class="mr-1 cursor-pointer" @click="() => toggleSort(column)">{{ column.title }}</span>
                                    <template v-if="isSorted(column)">
                                        <v-icon :icon="getSortIcon(column)"></v-icon>
                                    </template>
                                </div>
                            </template>
                            
                            <template v-slot:tfoot>
                                <tfoot>
                                    <v-data-table-headers>
                                        <template v-for="col in allColumns" :key="col.value" #[`header.${col.value}`]="{ column,isSorted, getSortIcon, toggleSort}">
                                            <div class="d-flex w-100 justify-content-between align-items-center">
                                                <span class="mr-1 cursor-pointer" @click="() => toggleSort(column)">{{ column.title }}</span>
                                                <template v-if="isSorted(column)">
                                                    <v-icon :icon="getSortIcon(column)"></v-icon>
                                                </template>
                                            </div>
                                        </template>
                                    </v-data-table-headers>
                                </tfoot>
                            </template>
                            <!-- <template v-slot:tfoot="{selectAll,allSelected,someSelected}">
                                
                                <v-checkbox :model-value="allSelected || someSelected" :indeterminate="someSelected && !allSelected" @change="selectAll(!(allSelected || someSelected))" label="Checkbox"></v-checkbox>
                                <div @click="console.log('props', props)">fffffffffffffff</div>
                            </template> -->
                            <template v-slot:bottom>
                                <v-container fluid>
                                    <v-row>
                                        
                                            <v-data-table-footer
                                                class="w-100 justify-content-start flex-wrap"
                                                :pagination="pagination"
                                                :options="options"
                                                show-current-page
                                                @update:options="updateOptions"
                                            >

                                                <template #prepend>

                                                    <div
                                                        class="d-flex justify-content-between align-items-center"
                                                    >
                                                            <v-btn variant="text" id="table-color-legend">
                                                                Color Legend
                                                                <i class="mdi mdi-help-circle"></i>
                                                                <v-tooltip
                                                                    id="custom-tooltip"
                                                                    :closeDelay="500"
                                                                    activator="parent"
                                                                    locationStrategy="connected"
                                                                    location="top"
                                                                    :persistent="true"
                                                                    
                                                                >
                                                                    <div
                                                                        class="d-flex flex-column gap-3 p-3"
                                                                    >
                                                                        <div
                                                                            v-for="item in statusItems"
                                                                            :key="item.name"
                                                                            class="d-flex align-center gap-3"
                                                                        >
                                                                            <div
                                                                                style="
                                                                                    width: 20px;
                                                                                    height: 20px;
                                                                                "
                                                                                :style="{
                                                                                    backgroundColor:
                                                                                        item.color,
                                                                                }"
                                                                            ></div>
                                                                            <span>{{ item.name }}</span>
                                                                        </div>
                                                                    </div>
                                                                </v-tooltip>
                                                            </v-btn>
                                                        
                                                    </div>
                                                </template>
                                            
                                            </v-data-table-footer>
                                    </v-row>
                                </v-container>

                            </template>
                            
                            <template #[`item.rejection_reason`]="{ item, index }">
                                <div>
                                    <div v-if="!showFull['reasons'][index]">
                                        {{ item.rejection_reason?.slice(0, 30) }}
                                        <span v-if="item.rejection_reason?.length > 30"
                                            >...</span
                                        >
                                        <span
                                            v-if="item.rejection_reason?.length > 30"
                                            class="text-decoration-underline pa-0 cursor-pointer"
                                            @click.stop="showFull['reasons'][index] = true"
                                        >
                                            Read more
                                        </span>
                                    </div>
                                    <div v-else>
                                        {{ item.rejection_reason }}
                                        <span
                                            class="text-decoration-underline pa-0 cursor-pointer"
                                            @click.stop="showFull['reasons'][index] = false"
                                        >
                                            Show less
                                        </span>
                                    </div>
                                </div>
                            </template>
                            <template #[`item.autologin_url`]="{ item, index }">
                                <div>
                                    <div v-if="!showFull['autologin_url'][index]">
                                        {{ item.autologin_url?.slice(0, 30) }}
                                        <span v-if="item.autologin_url?.length > 30"
                                            >...</span
                                        >
                                        <span
                                            v-if="item.autologin_url?.length > 30"
                                            class="text-decoration-underline pa-0 cursor-pointer"
                                            @click.stop="
                                                showFull['autologin_url'][index] = true
                                            "
                                        >
                                            Read more
                                        </span>
                                    </div>
                                    <div v-else>
                                        {{ item.autologin_url }}
                                        <span
                                            class="text-decoration-underline pa-0 cursor-pointer"
                                            @click.stop="
                                                showFull['autologin_url'][index] = false
                                            "
                                        >
                                            Show less
                                        </span>
                                    </div>
                                </div>
                            </template>
                            <template #[`item.redirect_url`]="{ item, index }">
                                <div>
                                    <div v-if="!showFull['redirect_url'][index]">
                                        {{ item.redirect_url?.slice(0, 30) }}
                                        <span v-if="item.redirect_url?.length > 30"
                                            >...</span
                                        >
                                        <span
                                            v-if="item.redirect_url?.length > 30"
                                            class="text-decoration-underline pa-0 cursor-pointer"
                                            @click.stop="
                                                showFull['redirect_url'][index] = true
                                            "
                                        >
                                            Read more
                                        </span>
                                    </div>
                                    <div v-else>
                                        {{ item.redirect_url }}
                                        <span
                                            class="text-decoration-underline pa-0 cursor-pointer"
                                            @click.stop="
                                                showFull['redirect_url'][index] = false
                                            "
                                        >
                                            Show less
                                        </span>
                                    </div>
                                </div>
                            </template>
                            <template #[`item.notes`]="{ item, index }">
                                <div>
                                    <div v-if="!showFull['notes'][index]">
                                        {{ item.notes?.slice(0, 30) }}
                                        <span v-if="item.notes?.length > 30">...</span>
                                        <span
                                            v-if="item.notes?.length > 30"
                                            class="text-decoration-underline pa-0 cursor-pointer"
                                            @click.stop="showFull['notes'][index] = true"
                                        >
                                            Read more
                                        </span>
                                    </div>
                                    <div v-else>
                                        {{ item.notes }}
                                        <span
                                            class="text-decoration-underline pa-0 cursor-pointer"
                                            @click.stop="showFull['notes'][index] = false"
                                        >
                                            Show less
                                        </span>
                                    </div>
                                </div>
                            </template>
                            <!-- ID Column -->
                            <template #[`item.id`]="{ item }">
                                <div
                                    class="d-flex align-center justify-space-between gap-2"
                                >
                                    <div class="d-flex gap-2">
                                        <v-img
                                            src="/assets/img/infobar.png"
                                            max-width="24"
                                            min-width="24"
                                            class="cursor-pointer"
                                            @click="leadHistory(item.id)"
                                        />
                                        <v-img
                                            v-if="hasPermission('LEADS_EDIT')"
                                            src="/assets/img/iconedit.png"
                                            max-width="24"
                                            min-width="24"
                                            class="cursor-pointer"
                                            @click="openUpdateLeadDialog(item.id)"
                                        />
                                        <v-img
                                            v-if="item.is_injected === 1"
                                            src="/assets/img/iconsyringe.png"
                                            max-width="24"
                                            min-width="24"
                                        />
                                    </div>
                                    <span class="text-grey-darken-1">{{ item.id }}</span>
                                </div>
                            </template>
                            <!-- Advertiser Name -->
                            <template #[`item.advertiser_name`]="{ item }">
                                <v-btn
                                    variant="text"
                                    :to="`/edit/advertiser/${item.advertiser_id}`"
                                    class="text-decoration-underline pa-0"
                                    style="text-transform:none;"
                                    @click.stop
                                >
                                    {{ item.advertiser_name }}
                                    ({{ item.advertiser_id }})
                                </v-btn>
                            </template>
                            <!-- Brand Status -->
                            <template #[`item.brand_status`]="{ item }">
                                <div class="d-flex align-center gap-2">
                                    <v-img
                                        src="/assets/img/infobar.png"
                                        min-width="24"
                                        max-width="24"
                                        class="cursor-pointer"
                                        @click="statusHistory(item.id)"
                                    />
                                    <span class="text-grey-darken-1">{{
                                        item.brand_status
                                    }}</span>
                                </div>
                            </template>
                            <!-- Country -->
                            <template #[`item.country_name`]="{ item }">
                                <CountryInfo :name="item.country_name" />
                            </template>
                            <!-- Publisher Name -->
                            <template #[`item.publisher_name`]="{ item }">
                                <v-btn
                                    variant="text"
                                    :to="`/edit/affiliate/${item.affiliate_id}`"
                                    class="text-decoration-underline pa-0"
                                    style="text-transform:none;"
                                    @click.stop
                                >
                                    {{ item.publisher_name }}
                                    ({{ item.affiliate_id }})
                                </v-btn>
                            </template>
                            <!-- Conversion Rate -->
                            <template #[`item.cr`]="{ item }"> {{ item.cr }}% </template>
                            <!-- Affiliate Conversion Rate -->
                            <template #[`item.aff_cr`]="{ item }">
                                {{ item.aff_cr }}%
                            </template>
                            <!-- Distribution Name -->
                            <template #[`item.distribution_name`]="{ item }">
                                <div v-if="item.dis_id">
                                    <v-btn
                                        variant="text"
                                        class="text-primary text-decoration-underline pa-0"
                                        style="text-transform:none;"

                                        @click="
                                            openDistributionModal({
                                                id: item.dis_id,
                                                page: 2,
                                                create: false,
                                            })
                                        "
                                    >
                                        {{ item.distribution_name }}
                                        ({{ item.dis_id }})
                                    </v-btn>
                                </div>
                                <div v-else>
                                    {{ item.distribution_name }}
                                    {{ item.dis_id ? `(${item.dis_id})` : "" }}
                                </div>
                            </template>
                            <!-- Status -->
                            <template #[`item.status`]="{ item }">
                                <!-- <v-chip size="small">
                                    {{ item.status }}
                                </v-chip> -->
                                <span v-if="item.status === 'FTD'" class="badge bg-success">
                                    {{ item.status }}
                                </span>
                                <span
                                    v-if="item.status === 'FTD REJECTED'"
                                    class="badge badge-info"
                                >
                                    {{ item.status }}
                                </span>
                                <span
                                    v-if="item.status === 'Pending FTD'"
                                    class="badge badge-warning"
                                >
                                    {{ item.status }}
                                </span>
                                <span
                                    v-if="item.status === 'Submitted'"
                                    class="badge badge-secondary"
                                >
                                    {{ item.status }}
                                </span>
                                <span
                                    v-if="item.status === 'Rejected'"
                                    class="badge badge-danger"
                                >
                                    {{ item.status }}
                                </span>
                                <span
                                    v-if="item.status === 'Accepted'"
                                    class="badge badge-primary"
                                >
                                    {{ item.status }}
                                </span>
                            </template>
                            <!-- Late FTD -->
                            <template #[`item.late_ftd`]="{ item }">
                                <v-chip
                                    :color="item.late_ftd === 'Yes' ? 'success' : 'error'"
                                    size="small"
                                >
                                    {{ item.late_ftd }}
                                </v-chip>
                            </template>
                            <!-- Actions -->
                            <template #[`item.actions`]="{ item }">
                                <div class="d-flex gap-2">
                                    <v-btn
                                        color="success"
                                        variant="outlined"
                                        size="small"
                                        @click="acceptLead(item.id)"
                                    >
                                        <v-icon size="small" class="mr-1"
                                            >mdi-check-circle</v-icon
                                        >
                                        Edit
                                    </v-btn>
                                    <v-btn
                                        color="error"
                                        variant="outlined"
                                        size="small"
                                        @click="deleteLeads(item.id)"
                                    >
                                        <v-icon size="small" class="mr-1"
                                            >mdi-close-circle</v-icon
                                        >
                                        Delete
                                    </v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-row>


    <!-- vuetify dialog with table -->
    <v-dialog v-model="statusHistoryDialog" max-width="900px">
        <v-card>
            <v-card-title>
                Status History
                <b> {{ statusHistoryEmail }} ({{ statusHistoryId }}) </b>
            </v-card-title>
            <v-card-text>
                <!-- :headers="statusHistoryHeaders" -->
                <v-data-table
                    :items="statusHistoryItems"
                    class="elevation-1"
                    :fixed-header="true"
                    :fixed-footer="true"
                    :sticky="true"
                    height="50vh"
                >
                    <template
                        v-slot:headers="{
                            columns,
                            isSorted,
                            getSortIcon,
                            toggleSort,
                        }"
                    >
                        <tr>
                            <template
                                v-for="column in columns"
                                :key="column.key"
                            >
                                <td>
                                    <span
                                        class="mr-2 cursor-pointer"
                                        @click="() => toggleSort(column)"
                                        >{{
                                            column.title
                                                .split("_")
                                                .map(
                                                    (word) =>
                                                        word
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        word.slice(1),
                                                )
                                                .join(" ")
                                        }}</span
                                    >
                                    <template v-if="isSorted(column)">
                                        <v-icon
                                            :icon="getSortIcon(column)"
                                        ></v-icon>
                                    </template>
                                </td>
                            </template>
                        </tr>
                    </template>
                    <!-- <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>
                            Status History
                            <b>
                                {{ statusHistoryEmail }} ({{ statusHistoryId }})
                            </b>
                        </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                    </v-toolbar>
                </template> -->
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- vuetify dialog with table -->
    <v-dialog v-model="progressLogsDialog" max-width="900px">
        <v-card>
            <v-card-title> Progress Logs </v-card-title>
            <v-card-text class="overflow-y-auto">
                <!-- :headers="statusHistoryHeaders" -->
                <v-data-table
                    :items="progressLogsItems"
                    class="elevation-1"
                    show-select
                    :item-value="(item) => item['Job Id']"
                    v-model="slectedProgressLogsRows"
                    :fixed-header="true"
                    :fixed-footer="true"
                    :sticky="true"
                    height="50vh"
                >
                    <!-- <template
                        v-slot:headers="{
                            columns,
                            isSorted,
                            getSortIcon,
                            toggleSort,
                        }"
                    >
                        <tr>
                            <template
                                v-for="column in columns"
                                :key="column.key"
                            >
                                <td>
                                    <span
                                        class="mr-2 cursor-pointer"
                                        @click="() => toggleSort(column)"
                                        >{{
                                            column.title
                                                .split("_")
                                                .map(
                                                    (word) =>
                                                        word
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        word.slice(1),
                                                )
                                                .join(" ")
                                        }}</span
                                    >
                                    <template v-if="isSorted(column)">
                                        <v-icon
                                            :icon="getSortIcon(column)"
                                        ></v-icon>
                                    </template>
                                </td>
                            </template>
                        </tr>
                    </template> -->
                    <template v-slot:top>
                        <div class="d-flex justify-end mb-2">
                            <StandardBtn
                                :disabled="slectedProgressLogsRows.length === 0"
                                text
                                type="delete"
                                @click="deleteProgressLogs"
                            >
                            </StandardBtn>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- vuetify dialog with table -->
    <v-dialog v-model="leadHistoryDialog" max-width="900px">
        <v-card>
            <v-card-title color="bar">
                Lead History
                <b> {{ leadHistoryEmail }} ({{ leadHistoryId }}) </b>
            </v-card-title>
            <v-card-text class="overflow-y-auto">
                <v-data-table
                    :items="leadHistoryItems"
                    class="elevation-1"
                    :fixed-header="true"
                    :fixed-footer="true"
                    :sticky="true"
                    height="50vh"
                >
                    <!-- modify header of all columns -->
                    <template
                        v-slot:headers="{
                            columns,
                            isSorted,
                            getSortIcon,
                            toggleSort,
                        }"
                    >
                        <tr>
                            <template
                                v-for="column in columns"
                                :key="column.key"
                            >
                                <td>
                                    <span
                                        class="mr-2 cursor-pointer"
                                        @click="() => toggleSort(column)"
                                        >{{
                                            column.title
                                                .split("_")
                                                .map(
                                                    (word) =>
                                                        word
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        word.slice(1),
                                                )
                                                .join(" ")
                                        }}</span
                                    >
                                    <template v-if="isSorted(column)">
                                        <v-icon
                                            :icon="getSortIcon(column)"
                                        ></v-icon>
                                    </template>
                                </td>
                            </template>
                        </tr>
                    </template>
                    <template #[`item.message`]="{ item, index }">
                        <div>
                            <div v-if="!showFull['message'][index]">
                                {{ item.message?.slice(0, 30) }}
                                <span v-if="item.message?.length > 30"
                                    >...</span
                                >
                                <span
                                    v-if="item.message?.length > 30"
                                    class="text-decoration-underline pa-0 cursor-pointer"
                                    @click.stop="
                                        showFull['message'][index] = true
                                    "
                                >
                                    Read more
                                </span>
                            </div>
                            <div v-else>
                                {{ item.message }}
                                <span
                                    class="text-decoration-underline pa-0 cursor-pointer"
                                    @click.stop="
                                        showFull['message'][index] = false
                                    "
                                >
                                    Show less
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #[`item.lead_details`]="{ item, index }">
                        <div>
                            <div v-if="!showFull['lead_details'][index]">
                                {{ item.lead_details?.slice(0, 30) }}
                                <span v-if="item.lead_details?.length > 30"
                                    >...</span
                                >
                                <span
                                    v-if="item.lead_details?.length > 30"
                                    class="text-decoration-underline pa-0 cursor-pointer"
                                    @click.stop="
                                        showFull['lead_details'][index] = true
                                    "
                                >
                                    Read more
                                </span>
                            </div>
                            <div v-else>
                                {{ item.lead_details }}
                                <span
                                    class="text-decoration-underline pa-0 cursor-pointer"
                                    @click.stop="
                                        showFull['lead_details'][index] = false
                                    "
                                >
                                    Show less
                                </span>
                            </div>
                        </div>
                    </template>
                    <!-- <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>
                                Lead History
                                <b>
                                    {{ leadHistoryEmail }} ({{ leadHistoryId }})
                                </b>
                            </v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                        </v-toolbar>
                    </template> -->
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- vuetify dialog with three text input field -->
    <v-dialog v-model="updateLeadDialog" persistent max-width="900px">
        <v-card>
            <v-card-title>
                <span class="headline"
                    >Update Lead
                    <b> {{ updateLeadEmail }} ({{ updateLeadId }}) </b>
                </span>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-text-field
                        v-model="revenue"
                        label="Revenue"
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="payout"
                        label="Payout"
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="status_override"
                        label="Status Override"
                        required
                    ></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="updateLeadDialog = false"
                    >Close</v-btn
                >
                <v-btn color="blue darken-1" text @click="updateLead"
                    >Save</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- override status dialog modal -->
    <v-dialog v-model="overrideStatusDialog" persistent max-width="900px">
        <v-card>
            <v-card-title>
                <span class="headline">Override Status</span>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-radio-group v-model="selectedOption" name="leadStatus">
                        <v-radio
                            label="Manual"
                            value="manual"
                            :input-value="selectedOption"
                        ></v-radio>
                        <v-radio
                            label="Choose from dropdown"
                            value="dropdown"
                            :input-value="selectedOption"
                        ></v-radio>
                    </v-radio-group>
                    <!-- Conditionally Display Text Field or Dropdown -->
                    <div v-if="selectedOption === 'manual'">
                        <v-text-field
                            placeholder="Enter manual input"
                            v-model="manualInput"
                            variant="outlined"
                            hide-details
                            clearable
                        ></v-text-field>
                    </div>
                    <div v-if="selectedOption === 'dropdown'">
                        <v-autocomplete
                            v-model="dropdownInput"
                            :items="dropdownStatuses"
                            placeholder="Select from dropdown"
                            item-title="brand_status"
                            item-value="brand_status"
                            variant="outlined"
                            hide-details
                            clearable
                        >
                            <template v-slot:selection="{ item }">
                                <v-chip small>
                                    <span>{{ item.value }}</span>
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </div>
                    <p>Are you sure you want to reject the field?</p>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="overrideStatusDialog = false"
                    >Close</v-btn
                >
                <v-btn color="blue darken-1" text @click="overrideStatus"
                    >Save</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- manage selection dialog modal -->
    <v-dialog v-model="manageSelectionDialog" max-width="700px">
        <v-card>
            <v-card-title>
                <span class="headline"
                    >Send leads to a different advertiser</span
                >
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-autocomplete
                        v-model="brandId"
                        :items="brands"
                        placeholder="Select a advertiser"
                        item-title="name"
                        item-value="id"
                        variant="outlined"
                        hide-details
                        clearable
                        :rules="[
                            (value) =>
                                !!value || 'Please select a valid advertiser',
                        ]"
                    >
                        <template v-slot:label>
                            <span>Advertiser</span>
                        </template>
                        <template v-slot:selection="{ item }">
                            <v-chip small>
                                <span>{{ item.title }}</span>
                            </v-chip>
                        </template>
                    </v-autocomplete>
                    <v-radio-group
                        class="ml-n2 mt-6"
                        v-model="locateLeadsBy"
                        label="Locate leads by"
                        inline
                        hide-details
                        color="primary"
                    >
                        <v-radio
                            label="System Default"
                            value="system"
                        ></v-radio>
                        <v-radio label="IP Address" value="ip"></v-radio>
                        <v-radio label="Phone" value="phone"></v-radio>
                    </v-radio-group>
                    <!-- simlate login checkbox -->
                    <v-checkbox
                        v-model="simulateLogin"
                        label="Simulate Login"
                        color="primary"
                        hide-details
                    ></v-checkbox>
                    <!-- delay checkbox -->
                    <v-checkbox
                        v-model="delay"
                        label="Delay"
                        color="primary"
                        hide-details
                    ></v-checkbox>
                    <!-- if delay is checked, show delay min and max delay input fields  -->
                    <div v-if="delay" class="d-flex gap-4">
                        <v-text-field
                            v-model="delayMin"
                            label="Min Delay"
                            variant="outlined"
                        ></v-text-field>
                        <v-text-field
                            v-model="delayMax"
                            label="Max Delay"
                            variant="outlined"
                        ></v-text-field>
                    </div>
                    <!-- seperator with heading Data override -->
                    <span class="headline">Data override</span>
                    <v-divider class="my-4"></v-divider>
                    <div
                        class="d-flex align-center gap-md-4 flex-wrap flex-md-nowrap"
                    >
                        <div
                            class="d-flex justify-space-between align-center gap-4 flex-grow-1 w-75"
                        >
                            <div class="w-50">Publisher</div>
                            <v-switch
                                class="w-50 flex-shrink-0"
                                v-model="affiliateOriginal"
                                color="primary"
                                label="Use Original"
                                hide-details
                            ></v-switch>
                        </div>
                        <div class="flex-grow-1 w-100">
                            <v-autocomplete
                                :disabled="affiliateOriginal"
                                v-model="affiliate"
                                :items="affiliates"
                                placeholder="Select an affiliate"
                                item-title="name"
                                item-value="id"
                                variant="outlined"
                                hide-details
                                clearable
                            >
                                <template v-slot:selection="{ item }">
                                    <v-chip small>
                                        <span>{{ item.title }}</span>
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </div>
                    </div>
                    <data> </data>
                    <div
                        class="d-flex align-center gap-md-4 flex-wrap flex-md-nowrap mt-md-4 mt-2"
                    >
                        <div
                            class="d-flex justify-space-between align-center gap-4 flex-grow-1 w-75"
                        >
                            <div class="w-50">Funnel Slug</div>
                            <v-switch
                                class="w-50 flex-shrink-0"
                                v-model="funnelOriginal"
                                color="primary"
                                label="Use Original"
                                hide-details
                            ></v-switch>
                        </div>
                        <div class="flex-grow-1 w-100">
                            <v-autocomplete
                                :disabled="funnelOriginal"
                                v-model="funnel"
                                :items="funnels"
                                placeholder="Select a funnel slug"
                                item-title="funnel"
                                item-value="funnel"
                                variant="outlined"
                                hide-details
                                clearable
                            >
                                <template v-slot:selection="{ item }">
                                    <v-chip small>
                                        <span>{{ item.title }}</span>
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </div>
                    </div>
                    <div
                        class="d-flex align-center gap-md-4 flex-wrap flex-md-nowrap mt-md-4 mt-2"
                    >
                        <div
                            class="d-flex justify-space-between align-center gap-4 flex-grow-1 w-75"
                        >
                            <div class="w-50">Publisher ID Override</div>
                            <v-switch
                                class="w-50 flex-shrink-0"
                                v-model="affiliateIdDefault"
                                color="primary"
                                label="Use default"
                                hide-details
                            ></v-switch>
                        </div>
                        <div class="flex-grow-1 w-100">
                            <v-text-field
                                v-model="affiliateId"
                                :disabled="affiliateIdDefault"
                                placeholder="Enter Publisher ID override"
                                variant="outlined"
                                hide-details
                                clearable
                            ></v-text-field>
                        </div>
                    </div>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="manageSelectionDialog = false"
                    >Close</v-btn
                >
                <v-btn
                    color="blue darken-1"
                    :disabled="!brandId"
                    text
                    @click="manageSelection"
                    >Confirm</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- mark as test leads dialog modal -->
    <v-dialog v-model="markAsTestLeadsDialog" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline">Confirm Action</span>
            </v-card-title>
            <v-card-text>
                Are you sure you want to mark the selected leads as test leads?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="markAsTestLeadsDialog = false"
                    >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="markAsTestLeads"
                    >Yes, Confirm</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- fire ftd dialog modal -->
    <v-dialog v-model="fireFTDDialog" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline">Confirm Action</span>
            </v-card-title>
            <v-card-text>
                Are you sure you want to fire the selected leads as FTD?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="fireFTDDialog = false"
                    >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="fireFTD"
                    >Yes, Confirm</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- search dialog modal -->
    <v-dialog v-model="searchDialog" persistent max-width="600px">
        <v-card>
            <v-toolbar>
                <v-toolbar-title>Search Multiple Records</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon="mdi-close" @click="closeSearchDialog"></v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <v-col>
                    <v-autocomplete
                        v-model="searchId"
                        :items="searchItems"
                        item-value="id"
                        item-title="label"
                        variant="outlined"
                        hide-details
                        density="compact"
                    ></v-autocomplete>
                </v-col>
                <v-col>
                    <v-textarea
                        v-model="searchValue"
                        placeholder="Search by selected field"
                        row-height="15"
                        variant="outlined"
                        density="compact"
                        hint="Type or paste content separated by commas (,) or new line"
                    ></v-textarea>
                </v-col>
            </v-card-text>
            <v-container>
                <v-card-actions class="px-4">
                    <v-spacer></v-spacer>
                    <v-btn 
                        variant="outlined"
                        color="#303f9f"
                        class="rounded"
                        @click="submitSearch({ global: false })"
                        
                    >
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                    
                    <v-btn
                        @click="submitSearch({ global: true })"
                        variant="outlined"
                        color="#303f9f"
                        class="rounded"
                    >
                        <v-icon>mdi-magnify-plus-outline</v-icon>
                    </v-btn>
                    <v-btn
                        @click="clearSearchField()"
                        variant="outlined"
                        color="#303f9f"
                        class="rounded"
                    >
                        <v-icon>mdi mdi-backspace</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-container>
        </v-card>
    </v-dialog>
    <CreateDistributionModal
        v-if="isCreateDistributionModal"
        @close="isCreateDistributionModal = false"
        :pageNumber="distributionModalPage"
        :distributionId="distributionUpdateId"
        :distributionData="distributionData"
    />
</template>
<script setup>
import CreateDistributionModal from "../distributions/CreateDistributionModal.vue";
import {
    ref,
    computed,
    watch,
    defineProps,
    onMounted,
    onBeforeUnmount,
    reactive,
} from "vue";
// import Vue3Datatable from "@bhplugin/vue3-datatable";
// import "@bhplugin/vue3-datatable/dist/style.css";
import Swal from "sweetalert2";
import {
    fetchLeadDetails,
    fetchStatusHistory,
    fetchLeadHistory,
    updateLeadDetails,
    requestStatusOverride,
    requestMarkAsTestLeads,
    fetchProgressLogs,
    fetchFilterData,
    requestManageSelection,
    requestFireFTD,
    fetchDistributionById,
    progressLogsCountRequest,
    deleteProgressLogsRequest,
    getDefaultColumns,
    setDefaultColumnsReq,
} from "./service";
import { useRouter } from "vue-router";
import { usePermissions } from "@/composables/usePermissions";
import { useStore } from "vuex";
const store = useStore();
const { hasPermission } = usePermissions();
const router = useRouter();
const props = defineProps({
    filterData: {
        type: Object,
        default: () => ({}),
    },
    showFilter:Boolean
    // availableStatuses: [],
});
const tableMaxHeight = ref(null)
const tableElement = ref(null)
function setTableMaxHeight() {
    const interval = setInterval(() => {
        if(tableElement?.value?.$el){
            const rect = tableElement.value.$el.getBoundingClientRect(); // Get element's position
            const distanceFromTop = rect.top;
            let marginBottom = 2
            if(window.innerWidth>1600){
                marginBottom = 1
            }
            tableMaxHeight.value = `${Math.floor( ((window.innerHeight - distanceFromTop) *100 /window.innerHeight))-marginBottom }vh`
            clearInterval(interval)
        }
    }, 300);
}

// const appUrl = computed(() => process.env.VUE_APP_APP_URL);
const params = reactive({
    current_page: 1,
    pagesize: 100,
});
const pageSize = ref(100);
const page = ref(1);
function handlePageChange(value) {
    page.value = value;
}
function handlePageSizeChange(value) {
    pageSize.value = value;
}
// function getRangeText(page, itemsPerPage) {
//     if(!rows?.value?.length ){ return 'Items per page'}
//     const start = (page - 1) * itemsPerPage + 1; // Starting row of the current page
//     const end = Math.min(page * itemsPerPage, rows.value.length); // Ending row of the current page

//     return `${start}-${end} of ${rows.value.length}`;

// }
// const paginationInfo = computed(() => {
//     if (Math.ceil(rows.value.length / pageSize.value) === 0) {
//         return "";
//     } else if (pageSize.value === -1) {
//         return `Page 1 of 1`;
//     } else {
//         return `Page ${page.value} of ${Math.ceil(rows.value.length / pageSize.value)}`;
//     }
// });
function handleTabChange(tab) {
    console.log(tab);
    if (tab === "details") return;
    router.push({
        name: "ReportsView",
        query: {
            tab: tab,
            filters: encodeURIComponent(JSON.stringify(props.filterData)),
        },
    });
}
// const datatable = ref(null);
const statusItems = ref([
    {
        name: "Rejected",
        color: "#ffb09c",
    },
    {
        name: "FTD",
        color: "#b6d7a8",
    },
    {
        name: "Pending FTD",
        color: "#ffe599",
    },
    {
        name: "FTD Rejected",
        color: "#d9d2e9",
    },
    {
        name: "Fake FTD",
        color: "#796b99",
    },
]);
const allColumns = ref([
    {
        value: "id",
        title: "ID",
        visible: true,
        sortable: true,
    },
    {
        value: "advertiser_name",
        title: "Advertiser",
        visible: true,
        sortable: true,
    },
    {
        value: "brand_status",
        title: "Advertiser Status",
        visible: true,
        sortable: true,
    },
    {
        value: "publisher_name",
        title: "Publisher",
        visible: true,
        sortable: true,
    },
    {
        value: "country_name",
        title: "Country",
        visible: true,
        sortable: true,
    },
    {
        value: "dis_id",
        title: "Distribution Id",
        visible: false,
        sortable: true,
    },
    {
        value: "distribution_name",
        title: "Distribution",
        visible: true,
        sortable: true,
    },
    {
        value: "status",
        title: "Status",
        visible: true,
        sortable: true,
    },
    {
        value: "email",
        title: "Email",
        visible: true,
        sortable: true,
    },
    {
        value: "rejection_reason",
        title: "Rejection Reason",
        visible: true,
        sortable: true,
    },
    {
        value: "created_at",
        title: "Created",
        visible: true,
        sortable: true,
    },
    {
        value: "status_override",
        title: "Status Override",
        visible: true,
        sortable: true,
    },
    {
        value: "notes",
        title: "Notes",
        visible: false,
        sortable: true,
    },
    {
        value: "funnel",
        title: "Funnel",
        visible: true,
        sortable: true,
    },
    {
        value: "offer",
        title: "Offer",
        visible: true,
        sortable: true,
    },
    {
        value: "ftd_date",
        title: "FTD",
        visible: true,
        sortable: true,
    },
    {
        value: "broker_first_time_deposit",
        title: "BFTD",
        visible: true,
        sortable: true,
    },
    {
        value: "real_conversion",
        title: "Real Conversion",
        visible: false,
        sortable: true,
    },
    {
        value: "revenue",
        title: "Revenue",
        visible: true,
        sortable: true,
    },
    {
        value: "payout",
        title: "Payout",
        visible: true,
        sortable: true,
    },
    {
        value: "first_name",
        title: "First Name",
        visible: true,
        sortable: true,
    },
    {
        value: "last_name",
        title: "Last Name",
        visible: true,
        sortable: true,
    },
    {
        value: "phone",
        title: "Phone",
        visible: true,
        sortable: true,
    },
    {
        value: "ip",
        title: "IP",
        visible: true,
        sortable: true,
    },
    // {
    //     value: "action",
    //     title: "Action",
    //     visible: false,
    //     sortable: true,
    // },
    {
        value: "custom_param_1",
        title: "Custom Param 1",
        visible: false,
        sortable: true,
    },
    {
        value: "custom_param_2",
        title: "Custom Param 2",
        visible: false,
        sortable: true,
    },
    {
        value: "custom_param_3",
        title: "Custom Param 3",
        visible: false,
        sortable: true,
    },
    {
        value: "custom_param_4",
        title: "Platform",
        visible: false,
        sortable: true,
    },
    {
        value: "custom_param_5",
        title: "Source Track",
        visible: false,
        sortable: true,
    },
    {
        value: "publisher_status",
        title: "Publisher Sale Status",
        visible: true,
        sortable: true,
    },
    {
        value: "autologged_at",
        title: "Autologged At",
        visible: true,
        sortable: true,
    },
    {
        value: "autologin_url",
        title: "Autologin URL",
        visible: true,
        sortable: true,
    },
    {
        value: "redirect_url",
        title: "Redirect URL",
        visible: true,
        sortable: true,
    },
    {
        value: "city",
        title: "City",
        visible: true,
        sortable: true,
    },
    {
        value: "funnel_override",
        title: "Funnel Override",
        visible: true,
        sortable: true,
    },
    {
        value: "publisher_id_override",
        title: "Publisher Override",
        visible: true,
        sortable: true,
    },
    {
        value: "language",
        title: "Language",
        visible: true,
        sortable: true,
    },
]);
const rows = ref([]);
const showFull = ref({
    reasons: [],
    autologin_url: [],
    redirect_url: [],
    notes: [],
    message: [],
    lead_details: [],
});
const totalSelectedRows = ref(0);
const dropdownOpen = ref(false);
const dropdown = ref(null);
const editId = ref(null);
const conversions = ref([
    {
        name: "All",
        value: null,
    },
    {
        name: "Pending",
        value: [6],
    },
    {
        name: "Converted",
        value: [5, 6, 7],
    },
    {
        name: "Non Converted",
        value: [2, 3],
    },
]);
const leads = ref([
    {
        name: "All",
        value: null,
    },
    {
        name: "Successful Only",
        value: [2, 5, 6, 7],
    },
    {
        name: "Failed Only",
        value: [3],
    },
]);
const selectedConversion = ref({
    name: "All",
    value: null,
});
const selectedLead = ref({
    name: "All",
    value: null,
});
const searchItems = ref([
    {
        id: "email",
        label: "Email",
    },
    {
        id: "first_name",
        label: "First Name",
    },
    {
        id: "last_name",
        label: "Last Name",
    },
    {
        id: "funnel",
        label: "Funnel",
    },
    {
        id: "custom_param_1",
        label: "Custom Param 1",
    },
    {
        id: "custom_param_2",
        label: "Custom Param 2",
    },
    {
        id: "custom_param_3",
        label: "Custom Param 3",
    },
    {
        id: "custom_param_4",
        label: "Custom Param 4",
    },
    {
        id: "custom_param_5",
        label: "Custom Param 5",
    },
    {
        id: "id",
        label: "Lead Id",
    },
    {
        id: "hit_id",
        label: "Hit Id",
    },
    {
        id: "ip",
        label: "IP Address",
    },
]);
const searchId = ref("email");
const searchValue = ref(null);
const searchMultiple = ref(false);
const searchDialog = ref(false);
// status history dialog modal
const statusHistoryDialog = ref(false);
const statusHistoryItems = ref([]);
const statusHistoryEmail = ref(null);
const statusHistoryId = ref(null);
// const statusHistoryHeaders = ref([
//     { title: "Date", key: "created_at" },
//     { title: "Advertiser Status", key: "advertiser_status" },
//     { title: "Publisher Status", key: "publisher_status" },
// ]);
// lead history dialog modal
const leadHistoryDialog = ref(false);
const leadHistoryItems = ref([]);
const leadHistoryEmail = ref(null);
const leadHistoryId = ref(null);
// progress logs dialog modal
const progressLogsDialog = ref(false);
const progressLogsItems = ref([]);
const slectedProgressLogsRows = ref([]);
// update lead dialog modal
const updateLeadDialog = ref(false);
const updateLeadId = ref(null);
const updateLeadEmail = ref(null);
const revenue = ref(0);
const payout = ref(null);
const status_override = ref("");
// override status dialog modal
const overrideStatusDialog = ref(false);
const selectedOption = ref("manual"); // This will be either 'manual' or 'dropdown'
const manualInput = ref(null); // To hold the value of the manual input
const dropdownStatuses = ref([]); // To hold the value of the dropdown statuses
const dropdownInput = ref(null); // To hold the value of the dropdown selection
// manage selection dialog modal
const manageSelectionDialog = ref(false);
const brandId = ref(null);
const brands = ref([]);
const locateLeadsBy = ref("system");
const simulateLogin = ref(false);
const delay = ref(false);
const delayMin = ref(null);
const delayMax = ref(null);
const affiliateOriginal = ref(true);
const affiliate = ref(null);
const affiliates = ref([]);
const funnelOriginal = ref(true);
const funnel = ref(null);
const funnels = ref([]);
const affiliateIdDefault = ref(true);
const affiliateId = ref(null);
// mark as test leads dialog modal
const markAsTestLeadsDialog = ref(false);
const fireFTDDialog = ref(false);
const slectedTableRows = ref([]);

const progressLogsCountLoading = ref(false);
const progressLogsCount = ref(0);
const fetchProgressLogsCount = async () => {
    try {
        progressLogsCountLoading.value = true;
        const data = await progressLogsCountRequest();
        if (!data) {
            throw new Error("Failed to fetch data");
        }
        progressLogsCount.value = data?.count || 0;
    } catch (error) {
        progressLogsCount.value = 0;
        console.log("Error on fetching progress logs count", error);
    } finally {
        progressLogsCountLoading.value = false;
    }
};
const manageSelection = async () => {
    const selectedRows = rows.value?.filter((row) =>
        slectedTableRows.value?.includes(row.id),
    );
    if (selectedRows.length === 0) {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "No row selected",
        });
        manageSelectionDialog.value = false;
        return;
    }
    try {
        const lead_ids = selectedRows.map((row) => row.id);
        const payload = {
            lead_ids: lead_ids,
            advertiser_id: brandId.value,
            locate_leads: locateLeadsBy.value,
            simulate: simulateLogin.value,
            min_delay: delay.value ? delayMin.value : null,
            max_delay: delay.value ? delayMax.value : null,
            publisher_id: !affiliateOriginal.value ? affiliate.value : null,
            funnel: !funnelOriginal.value ? funnel.value : null,
            publisher_id_override: !affiliateIdDefault.value
                ? affiliateId.value
                : null,
        };
        const data = await requestManageSelection(payload);
        if (!data) {
            throw new Error("Failed to update data");
        }
        Swal.fire({
            icon: "success",
            title: "Successfully Updated",
        });
        //clear selected rows
        slectedTableRows.value = []
        console.log("data", data);
    } catch (error) {
        console.log("error on manage selection", error);
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to update. Please try again.",
        });
    } finally {
        manageSelectionDialog.value = false;
    }
};
const resetManageSelectionDialog = () => {
    brandId.value = null;
    brands.value = [];
    locateLeadsBy.value = "system";
    simulateLogin.value = false;
    delay.value = false;
    delayMin.value = null;
    delayMax.value = null;
    affiliateOriginal.value = true;
    affiliate.value = null;
    affiliates.value = [];
    funnelOriginal.value = true;
    funnel.value = null;
    funnels.value = [];
    affiliateIdDefault.value = true;
    affiliateId.value = null;
};
const openManageSelectionDialog = async () => {
    resetManageSelectionDialog();
    manageSelectionDialog.value = true;
    try {
        const data = await fetchFilterData();
        if (!data) {
            throw new Error("Failed to fetch data");
        }
        brands.value = data.advertisers;
        affiliates.value = data.publishers;
        funnels.value = data.funnel;
    } catch (error) {
        console.log("error on opening override status dialog", error);
    }
};
const resetOverrideStatusDialog = () => {
    manualInput.value = null;
    dropdownInput.value = null;
    selectedOption.value = "manual";
    dropdownStatuses.value = [];
};
const openOverrideStatusDialog = async () => {
    resetOverrideStatusDialog();
    overrideStatusDialog.value = true;
    try {
        const data = await fetchFilterData();
        if (!data) {
            throw new Error("Failed to fetch data");
        }
        console.log("data", data);
        dropdownStatuses.value = data.statuses.filter(
            (val) => !!val.brand_status,
        );
    } catch (error) {
        console.log("error on opening override status dialog", error);
    }
};
const overrideStatus = async () => {
    try {
        console.log("override status", dropdownInput.value);
        const selectedRows = rows.value?.filter((row) =>
            slectedTableRows.value?.includes(row.id),
        );
        const lead_ids = selectedRows.map((row) => row.id);
        if (!lead_ids.length) {
            throw new Error("NO_ROW_SELECTED");
        }
        const payload = {
            lead_ids: lead_ids,
            status_override:
                selectedOption.value.toLowerCase() === "manual"
                    ? manualInput.value
                    : dropdownInput.value,
        };
        const data = await requestStatusOverride(payload);
        if (!data) {
            throw new Error("Failed to fetch data");
        }
        Swal.fire({
            icon: "success",
            title: "Status Override Updated",
        });
        
    } catch (error) {
        if (error.message === "NO_ROW_SELECTED") {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "No row selected",
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to update the lead. Please try again.",
            });
        }
    } finally {
        overrideStatusDialog.value = false;
    }
};
const copyEmailToClipboard = () => {
    const selectedRows = rows.value?.filter((row) =>
        slectedTableRows.value?.includes(row.id),
    );
    if (selectedRows.length === 0) {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "No row selected",
        });
        return;
    }
    const emails = selectedRows.map((row) => row.email);
    console.log("emails", emails);
    navigator.clipboard.writeText(emails.join(", "));
    Swal.fire({
        icon: "success",
        title: "Emails copied to clipboard",
    });
    //clear selected rows
};
const markAsTestLeads = async () => {
    const selectedRows = rows.value?.filter((row) =>
        slectedTableRows.value?.includes(row.id),
    );
    if (selectedRows.length === 0) {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "No row selected",
        });
        markAsTestLeadsDialog.value = false;
        
        return;
    }
    try {
        const lead_ids = selectedRows.map((row) => row.id);
        const payload = {
            lead_ids: lead_ids,
        };
        const data = await requestMarkAsTestLeads(payload);
        if (!data) {
            throw new Error("Failed to fetch data");
        }
        Swal.fire({
            icon: "success",
            title: "Leads Marked as Test",
        });
    } catch (error) {
        console.log("error on marking as test leads", error);rows.value
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to update the lead. Please try again.",
        });
    } finally {
        markAsTestLeadsDialog.value = false;
    }
};
const countSelectedRows = (rows) => {
    totalSelectedRows.value = rows.length;
};
const statusHistory = async (id) => {
    const selectedRowData = rows.value.find((row) => row.id === id);
    statusHistoryEmail.value = selectedRowData.email;
    statusHistoryId.value = id;
    try {
        const payload = {
            tz_offset: "+03:00",
            lead_id: id,
        };
        const data = await fetchStatusHistory(payload);
        if (!data) {
            throw new Error("Failed to fetch data");
        }
        // console.log("data", data);
        statusHistoryItems.value = data;
        statusHistoryDialog.value = true;
    } catch (error) {
        console.log("error on fetching status history", error);
    }
};
const openProgressLogsDialog = async () => {
    progressLogsDialog.value = true;
    try {
        const data = await fetchProgressLogs();
        if (!data) {
            throw new Error("Failed to fetch data");
        }
        progressLogsItems.value = data;
    } catch (error) {
        console.log("error on fetching progress logs", error);
    }
};
const deleteProgressLogs = async () => {
    const data = await deleteProgressLogsRequest({
        ids: slectedProgressLogsRows.value,
    });
    if (data) {
        Swal.fire({
            icon: "success",
            title: "Progress Logs Deleted",
        });
        progressLogsItems.value = progressLogsItems.value.filter(
            (item) => !slectedProgressLogsRows.value.includes(item["Job Id"]),
        );
        
    } else {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to delete the progress logs. Please try again.",
        });
    }
};
const fireFTD = async () => {
    const selectedRows = rows.value?.filter((row) =>
        slectedTableRows.value?.includes(row.id),
    );
    if (selectedRows.length === 0) {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "No row selected",
        });
        fireFTDDialog.value = false;
        return;
    }
    try {
        const lead_ids = selectedRows.map((row) => row.id);
        const payload = {
            lead_ids: lead_ids,
        };
        const data = await requestFireFTD(payload);
        if (!data) {
            throw new Error("Failed to fire FTD");
        }
        Swal.fire({
            icon: "success",
            title: "FTD Fired",
        });
        
    } catch (error) {
        console.log("error on firing FTD", error);
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to fire FTD. Please try again.",
        });
    } finally {
        fireFTDDialog.value = false;
    }
};
const leadHistory = async (id) => {
    const selectedRowData = rows.value.find((row) => row.id === id);
    leadHistoryEmail.value = selectedRowData.email;
    leadHistoryId.value = id;
    try {
        const payload = {
            tz_offset: "+03:00",
            lead_id: id,
        };
        const data = await fetchLeadHistory(payload);
        if (!data) {
            throw new Error("Failed to fetch data");
        }
        // console.log("data", data);
        leadHistoryItems.value = data;
        for (let item in showFull.value) {
            // console.log("item", item);
            if (item === "message" || item === "lead_details") {
                item = Array(leadHistoryItems.value.length).fill(false);
            }
        }
        leadHistoryDialog.value = true;
    } catch (error) {
        console.log("error on fetching lead history", error);
    }
};
const openUpdateLeadDialog = (id) => {
    console.log("Lead: " + id);
    editId.value = id;
    // autofill form
    const selectedRowData = rows.value.find((row) => row.id === id);
    updateLeadId.value = id;
    updateLeadEmail.value = selectedRowData.email;
    console.log("selected Row data", selectedRowData);
    revenue.value = selectedRowData.revenue;
    payout.value = selectedRowData.payout;
    status_override.value = selectedRowData.status_override;
    // show modal
    updateLeadDialog.value = true;
};


const openSearchDialog = () => {
    if(searchMultiple.value){
        searchDialog.value = true;
    }
};
const toggleSearchMultiple = () => {
    if (searchMultiple.value) {
        searchMultiple.value = false
    }else{
        searchMultiple.value = true
        openSearchDialog()
    }
}
const closeSearchDialog = () => {
    searchDialog.value = false;
    // searchId.value = "email";
    // searchValue.value = null;
};
const submitSearch = async (payload) => {
    searchLeads(payload);
    closeSearchDialog();
};
const updateLead = async () => {
    try {
        console.log(editId.value);
        const payload = {
            lead_id: editId.value,
            revenue: revenue.value,
            payout: payout.value,
            status_override: status_override.value,
        };
        const data = await updateLeadDetails(payload);
        if (!data) {
            throw new Error("Failed to fetch data");
        }
        Swal.fire({
            icon: "success",
            title: "Lead Updated",
        });
    } catch (error) {
        console.log("failed to update lead details", error);
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to update the lead. Please try again.",
        });
    } finally {
        updateLeadDialog.value = false;
    }
};
function toggleDropdown() {
    dropdownOpen.value = !dropdownOpen.value;
}
function toggleColumnVisibility(field) {
    const column = allColumns.value.find((col) => col.value === field.value);
    if (column) {
        column.visible = !column.visible;
    }
}
// eslint-disable-next-line no-unused-vars
const visibleColumns = computed(() => {
    return allColumns.value.filter((col) => col.visible);
});
function handleClickOutside(event) {
    if (dropdown.value && !dropdown.value.contains(event.target)) {
        dropdownOpen.value = false;
    }
}
async function setDefaultColumns() {
    const payload = {
        user_id: store.getters.getUser.user_id,
        extras: {
            lead_details_columns: allColumns.value,
        },
    };

    const response = await setDefaultColumnsReq(payload);

    if (response) {
        Swal.fire({
            icon: "success",
            title: "Default Columns Set Successfully",
            // text: response.message,
        });
    } else {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to set default columns. Please try again.",
        });
    }
}
onMounted(async () => {
    window.addEventListener("resize", setTableMaxHeight);
    document.addEventListener("click", handleClickOutside);
    const defaultColumns = await getDefaultColumns({
        user_id: store.getters.getUser.user_id,
    });
    allColumns.value = JSON.parse(
        JSON.stringify(defaultColumns["lead_details_columns"]),
    );
    setTableMaxHeight()
});
onBeforeUnmount(() => {
    document.removeEventListener("click", handleClickOutside);
    document.removeEventListener("resize", setTableMaxHeight);
});
const searchLeads = async ({ global }) => {
    loading.value = true;
    const filterData = props.filterData;
    try {
        const payload = {
            tz_offset: "+03:00",
            advertiser_id: filterData?.advertiser_id || null, // Adjusted to match the parameters
            country_id: filterData?.country_id || null,
            publisher_id: filterData?.publisher_id || null,
            brand_status: filterData?.brand_status || null,
            start_date: filterData?.start_date || null, // Send null if date is not provided
            end_date: filterData?.end_date || null,
            start_time: filterData?.start_time || null,
            end_time: filterData?.end_time || null,
            exclude_fake_FTD: filterData?.exclude_fake_FTD ?? false,
            is_test: filterData?.is_test ?? false,
            custom_param_1: filterData?.custom_param_1 || null,
            custom_param_2: filterData?.custom_param_2 || null,
            custom_param_3: filterData?.custom_param_3 || null,
            custom_param_4: filterData?.custom_param_4 || null,
            custom_param_5: filterData?.custom_param_5 || null,
            publisher_status: filterData?.publisher_status || null,
            offer: filterData?.offer || null,
            excludes: filterData?.excludes || [],
            email: null,
            dis_id: filterData?.dis_id || null,
            funnel: filterData?.funnel || null,
            search_txt: searchValue.value ? [searchValue.value] : null,
            search_in: searchId.value,
            is_search_global: global,
        };
        // console.log(
        //     "logging",
        //     ...(selectedConversion.value?.value || []),
        // );
        // add selectedLead, selectedConversion
        payload["lead_status_id"] = [
            ...new Set([
                ...(selectedLead.value?.value || []),
                ...(selectedConversion.value?.value || []),
            ]),
        ];
        if (!payload["lead_status_id"]?.length) {
            payload["lead_status_id"] = null;
        }
        const data = await fetchLeadDetails(payload);
        if (!data) {
            throw new Error("Failed to fetch data");
        }
        console.log("Updated rows", data);
        rows.value = data;
        for (let item in showFull.value) {
            // console.log("item", item);
            if (
                item === "reasons" ||
                item === "autologin_url" ||
                item === "redirect_url" ||
                item === "notes"
            ) {
                item = Array(rows.value.length).fill(false);
            }
        }
    } catch (error) {
        console.error("Failed to fetch data:", error);
    } finally {
        loading.value = false;
    }
};

const clearSearchField = async () => {
    try {
        searchValue.value = "";
        searchLeads({global:false})
    } catch (error) {
        console.error("Failed to clear data:", error);
    }
};

const fetchRowsByFilter = async (filterData) => {
    loading.value = true;
    console.log("Filter data:", filterData);
    try {
        const payload = {
            tz_offset: "+03:00",
            last_24_hours: filterData?.last_24_hours ?? false,
            inside_selected_hour_range:
                filterData?.inside_selected_hour_range ?? false,
            created_inside_date_range:
                filterData?.created_inside_date_range ?? false,
            compare_with_past: filterData?.compare_with_past ?? false,
            exclude_fake_FTD: filterData?.exclude_fake_FTD ?? false,
            is_test: filterData?.is_test ?? false,
            advertiser_id: filterData?.advertiser_id || null, // Adjusted to match the parameters
            country_id: filterData?.country_id || null,
            publisher_id: filterData?.publisher_id || null,
            brand_status: filterData?.brand_status || null,
            start_date: filterData?.start_date || null, // Send null if date is not provided
            end_date: filterData?.end_date || null,
            start_time: filterData?.start_time || null,
            end_time: filterData?.end_time || null,
            custom_param_1: filterData?.custom_param_1 || null,
            custom_param_2: filterData?.custom_param_2 || null,
            custom_param_3: filterData?.custom_param_3 || null,
            custom_param_4: filterData?.custom_param_4 || null,
            custom_param_5: filterData?.custom_param_5 || null,
            publisher_status: filterData?.publisher_status || null,
            offer: filterData?.offer || null,
            dis_id: filterData?.dis_id || null,
            funnel: filterData?.funnel || null,
            email: null,
            excludes: filterData?.excludes || [],
            search_txt: searchValue.value ? [searchValue.value] : null,
            search_in: searchId.value,
            is_search_global: false,
        };
        // console.log(
        //     "logging",
        //     ...(selectedConversion.value?.value || []),
        // );
        // add selectedLead, selectedConversion
        payload["lead_status_id"] = [
            ...new Set([
                ...(selectedLead.value?.value || []),
                ...(selectedConversion.value?.value || []),
            ]),
        ];
        if (!payload["lead_status_id"]?.length) {
            payload["lead_status_id"] = null;
        }
        const data = await fetchLeadDetails(payload);
        if (!data) {
            throw new Error("Failed to fetch data");
        }
        console.log("Updated rows", data);
        rows.value = data;
        for (let item in showFull.value) {
            // console.log("item", item);
            if (
                item === "reasons" ||
                item === "autologin_url" ||
                item === "redirect_url" ||
                item === "notes"
            ) {
                item = Array(rows.value.length).fill(false);
            }
        }
        // showFullReasons.value = Array(rows.value.length).fill(false);
    } catch (error) {
        console.error("Failed to fetch data:", error);
    } finally {
        loading.value = false;
    }
};
const isCreateDistributionModal = ref(false);
const distributionModalPage = ref(1);
const distributionUpdateId = ref(null);
const distributionData = ref(null);
async function openDistributionModal({ id, page, create }) {
    // console.log(id, page, create);
    distributionData.value = null;
    distributionModalPage.value = page || 1;
    distributionUpdateId.value = id;
    if (create) {
        distributionUpdateId.value = null;
    }
    if (!id) {
        isCreateDistributionModal.value = true;
        return;
    }
    // fetch the id data
    distributionData.value = await fetchDistributionById(id);
    isCreateDistributionModal.value = true;
}
watch(
    () => props.filterData,
    (newValue) => {
        console.log("New value:", newValue);
        fetchRowsByFilter(newValue);
    },
    {
        deep: true,
    },
);
const loading = ref(false);
const exportTable = (type) => {
    let records = [];
    if (slectedTableRows.value?.length === 0) {
        records = rows.value;
    } else {
        records = rows.value?.filter((row) =>
            slectedTableRows.value?.includes(row.id),
        );
    }
    console.log("rows to export", records);
    console.log("visibleColumns", visibleColumns);
    // visibleColumns
    // if (!records?.length) {
    //     records = rows.value;
    // }
    const filename = "lead-data";
    if (type === "csv") {
        // CSV or TXT
        const coldelimiter = ",";
        const linedelimiter = "\n";
        let result = visibleColumns.value
            .map((d) => d.title)
            .join(coldelimiter);
        result += linedelimiter;
        records.forEach((item) => {
            visibleColumns.value.forEach((d) => {
                // console.log("d", d);
                // if (index > 0) {
                //     result += coldelimiter;
                // }
                // // Get the value and replace null/undefined with a space
                // const val =
                //     d.value
                //         ?.split(".")
                //         ?.reduce((acc, part) => acc && acc[part], item) || " ";

                // console.log("val", val);
                if (
                    item[d.value] === null ||
                    item[d.value] === undefined ||
                    !item[d.value]
                ) {
                    result += coldelimiter;
                } else if (typeof item[d.value] === "string") {
                    result +=
                        `"${item[d.value]?.replaceAll("\n", " ").replaceAll('"', '""').replaceAll(",", " ")}"` +
                        coldelimiter;
                } else if (typeof item[d.value] === "object") {
                    console.log("object found", item[d.value]);
                    result += item[d.value] + coldelimiter;
                } else {
                    result += item[d.value] + coldelimiter;
                }
            });
            result += linedelimiter;
        });
        if (result !== null) {
            var data =
                "data:application/csv;charset=utf-8," +
                encodeURIComponent(result);
            var link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", filename + ".csv");
            link.click();
        }
    }
};
const setBackgroundColor = (data) => {
    return {
        style: `background-color: ${data.item.row_color ? data.item.row_color : "white"};`,
    };
};





//-------------


</script>
<style>
#custom-tooltip .v-overlay__content{
    pointer-events: auto !important;
}
</style>

<style scoped>

 /*------------------tabs-----------------------*/
::v-deep .v-tab--selected {
  background-color: white; /* Custom background color */
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  overflow: hidden;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.16);
}

/*------------------table footer-------------------------*/
::v-deep .v-data-table-footer{
justify-content: space-between;
    gap :8px;
}

::v-deep .v-data-table-footer .v-data-table-footer__items-per-page{
    justify-content: end;
    flex-grow: 1;

}
::v-deep .v-data-table-footer .v-data-table-footer__items-per-page .v-input{
    flex-grow: 0 !important;
}


::v-deep .v-data-table-footer .v-data-table-footer__pagination{
     margin-left: auto ;
    flex-grow: 0;
   
}
::v-deep .v-data-table-footer .v-data-table-footer__pagination .v-pagination__list{
    margin-bottom:  0 ;
}


/*------------------table---------*/

#main-lead-table{
    max-height:80vh;
}
@media (min-width:1900px) {
    #main-lead-table{
        max-height:v-bind(tableMaxHeight);
    }
}
/*---------table loading progress bar------------------*/
::v-deep table > thead > tr.v-data-table-progress{
    display: none !important; 
}

::v-deep thead > tr:not(.v-data-table-progress),
::v-deep th {
    background: black !important;
    color: rgba(255, 255, 255, 1) !important;
    white-space: nowrap;
    height: fit-content !important;
}
::v-deep th:hover {
    color: rgba(255, 255, 255, 0.8) !important;
}

::v-deep table {
    border-collapse:separate;
}

::v-deep table > thead > tr:not(.v-data-table-progress) > th,
::v-deep table > tfoot > tr:not(.v-data-table-progress) > th{
    border-right: thin solid rgba(255, 255, 255, 0.2);
    padding: 8px 10px !important;
    font-size: 13px;
}

::v-deep table > thead > tr:not(.v-data-table-progress) > th>div,
::v-deep table > tfoot > tr:not(.v-data-table-progress) > th>div{
    display: flex !important; 
    width: 100%;
    justify-content: center;
}
::v-deep table > thead > tr:not(.v-data-table-progress) > th:last-child ,
::v-deep table > tfoot > tr:not(.v-data-table-progress) > th:last-child {
    border-right: none;
}

::v-deep table > tbody > tr:not(.v-data-table-progress) > td {
    border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
    padding: 6px 10px !important;
    font-size: 13px !important;
    font-weight: 500;
    height: 18px !important;

}

::v-deep table tr td a , ::v-deep table tr td * , ::v-deep table tr:not(.v-data-table-progress) th * {
    font-size: 13px !important;
    font-weight: 500;

    height:18px !important;
}

::v-deep table > tbody > tr > td > .v-selection-control  i 
,::v-deep table > thead > tr > th > .v-selection-control  i
,::v-deep table > tfoot > tr > th > .v-selection-control  i{
    transform: scale(1.6) !important;
}
::v-deep table > tbody > tr > td > .v-selection-control  .v-ripple__container , 
::v-deep table > thead > tr > th > .v-selection-control  .v-ripple__container,
::v-deep table > tfoot > tr > th > .v-selection-control  .v-ripple__container{
    display: none !important;
}
::v-deep table > tbody > tr > td > .v-selection-control .v-selection-control__input::before,
::v-deep table > thead > tr > th > .v-selection-control .v-selection-control__input::before,
::v-deep table > tfoot > tr > th > .v-selection-control .v-selection-control__input::before{
    opacity: 0 !important;
}


::v-deep table > thead > tr > th:last-child ,
::v-deep tfoot > thead > tr > th:last-child {
    border-right: none;
}

::v-deep table tr td {
    border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
    padding: 0 10px !important;
   
}
::v-deep table tr td:last-child {
    border-right: none;
   
}
.table-container {
    max-height: 75vh;
    /* Set the height you need */
    overflow-y: auto;
    /* Enable vertical scrolling */
    overflow-x: auto;
    /* Enable horizontal scrolling if needed */
}
.status {
    text-align: center;
}
.vue3-datatable {
    width: 100%;
    /* Make sure the table takes the full width of the container */
}
.vue3-datatable thead th {
    position: sticky;
    top: 0;
    /* Stick the header to the top */
    background: white;
    /* Set a background color for better visibility */
    z-index: 1;
    /* Ensure the header is above the table body */
}
/* Change the header background color */
.bh-datatable .bh-table thead th {
    background-color: black;
    /* Change to your desired color */
    color: #fff;
    /* Change to text color */
}
.dropdown {
    position: relative;
    display: inline-block;
}
.dropdown-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}
.dropdown-button:hover {
    background-color: #45a049;
}
.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 10px;
}
.dropdown-content label {
    display: block;
    padding: 5px 0;
}
.dropdown-content label:hover {
    background-color: #f1f1f1;
}

/*---------------------search input--------------------------------*/
/* Style the container with Vuetify's outline variant colors */
#search-input-container{
  position: relative;
}
#search-input-container:has(#search-input.wrapped){
  margin-bottom : 40px;
}
#search-input {
    
  display: flex;
  align-items: center;
   /* Space between items */
  border: 1px solid rgba(var(--v-theme-on-surface),0.38); /* Default outline color */
  border-radius: 4px; /* Optional: rounded edges */
  transition: border-color 0.3s ease;
  height: 40px;
}

#search-input:hover {
  border: 1px solid rgba(var(--v-theme-on-surface),1); /* Default outline color */
}
#search-input:focus-within
{
  border: 2px solid rgba(var(--v-theme-on-surface),1); /* Default outline color */
}
#search-input.wrapped {
    border-bottom-right-radius: 0;
}

/* color: rgba(var(--v-theme-on-background), */
#search-input-container label {
  color:rgba(73, 80, 87, 0.6) !important;
  background-color: rgb(var(--v-theme-background));
  position: absolute;
  top:-12px;
  left:62px;
  padding:0 4px;
}
#search-input-container:focus-within  label{
  color:rgba(73, 80, 87, 1) !important;
}
/* Divider styling */
#search-input .custom-divider {
  margin: 0px 0;
  border-width: 0 2px 0 0;
}

/* Remove borders and styles for inputs */
#search-input .no-borders {
  --v-field-border-width: 0;
  --v-field-outline-width: 0;
  --v-field-padding: 0 12px;
  --v-field-background: transparent;
  box-shadow: none;
}

#search-input .no-borders .v-input__control {
  border: none;
}

#search-input .custom-autocomplete-container{
  margin: 0 4px ;
  flex-shrink: 0;
}
#search-input.wrapped .custom-autocomplete-container{
    max-width: 5.5rem !important;
    overflow: hidden;
}
#search-input .custom-textarea-container{
  width: 60%;
  flex-grow: 1;
  flex-shrink: 1;

}
::v-deep #search-input .custom-autocomplete .v-field__input{
  padding-left: 8px;
  padding-right: 0;
}
::v-deep #search-input .custom-autocomplete .v-field__input .v-autocomplete__selection{
  max-width: none;
  min-width:max-content;
}
::v-deep #search-input .custom-autocomplete .v-field,
::v-deep #search-input .custom-textarea .v-field
{
    --v-field-input-padding-bottom : 8px;
    --v-field-input-padding-left : 8px;
    
}
::v-deep #search-input .custom-textarea .v-field{
  padding-left: 12px;
  padding-right: 12px;
}


#search-input.wrapped .search-input-btns-container {
    position: absolute;
    top: 100%;
    right: 0;
    border: 1px solid rgba(var(--v-theme-on-surface),0.38); /* Default outline color */
    border-top: none;
    /* border-radius: 4px; */
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

}
#search-input.wrapped .search-input-btns-container .first-divider{
    display: none;
}
#search-input.wrapped:hover .search-input-btns-container{
  border: 1px solid rgba(var(--v-theme-on-surface),1); /* Default outline color */
    border-top: none;

}
#search-input.wrapped:focus-within .search-input-btns-container.wrapped
{
  border: 2px solid rgba(var(--v-theme-on-surface),1); /* Default outline color */
    border-top: none;

}

/*---------------------table selection------------------------*/
#clear-selections-container{
    margin-bottom: 4px !important;
    font-family: system-ui, -apple-system, sans-serif;
    font-size: 1rem !important;
    font-style:italic;
}
#clear-selections{
    font-family: system-ui, -apple-system, sans-serif;
    color: #303f9f;
    cursor: pointer;
}
#clear-selections:hover{
    text-decoration: underline !important;
    text-underline-offset: 3px;
}
</style>