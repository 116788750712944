<template>
<ContentLayout>
    <template #filter>
        <FilterComponent class="elevation-3" />
    </template>
    <template #default>
        <DataTableComponent :filterData="filterData" :showFilter="showFilter" />
    </template>
</ContentLayout>
</template>

<script>
import ContentLayout from "../layout/ContentLayout.vue";
import FilterComponent from "../components/leads/FilterComponent.vue";
import DataTableComponent from "../components/leads/DataTableComponent.vue";
import { mapGetters } from "vuex";
export default {
    name: "LeadsView",
    components: {
        ContentLayout,
        FilterComponent,
        DataTableComponent,

    },
    computed: {
        ...mapGetters({ filterData: "getFilterData" }),
    },
    data() {
        return {
            showFilter: true,
        };
    },
};
</script>


<style scoped>
@media (min-width:960px) {
    
    .leads-view-content .col1{
        width: 30% !important;
        flex-shrink: 0 !important;
        min-width: 24rem;
        max-width: 24rem;
    }
    .leads-view-content .col2{
        width: 40% !important;
        flex-grow: 1 !important;
    }
}
 @media (min-width:1600px) {
   .leads-view-content .col2{
        width: 70% !important;
   }
   
   
    
} 
 @media (min-width:1900px) {
    .leads-view-content .col1{
        min-width: 26rem;
        max-width: 26rem;
    }
 }

</style>
